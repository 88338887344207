import axios from 'axios';
import React, {useState, useEffect} from 'react';

import {Link, useNavigate} from 'react-router-dom';
import {formValidation} from '../../helper/common';
import HeaderAuthentication from '../common/HeaderAuthentication';
import {useUiContext} from "../../contexts/UiContext";

export default function SignupAddOtp() {
    const {apiRequest} = useUiContext();
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [signUpFormData, setSignUpFormData] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        try {
            const errorsData = JSON.parse(localStorage.getItem("signUpFormErrors"));
            const newOtpError = errorsData.errors?.code;
            if (newOtpError) setOtpError(newOtpError.join(", "));
        } catch {
        }
        try {
            const localData = JSON.parse(localStorage.getItem("signUpFormData"));
            if (localData) {
                setSignUpFormData(localData);
            } else {
                const errorsData = {name: ["Verify Code Signup Data missing"]};
                localStorage.setItem("signUpFormErrors", JSON.stringify(errorsData));
                navigate('/sign-up');
            }
        } catch (err) {
            const errorsData = {name: [`${err}`]};
            localStorage.setItem("signUpFormErrors", JSON.stringify(errorsData));
            navigate('/sign-up');
        }
    }, []);

    useEffect(() => {
        formValidation({otp}, setDisabled);
        setSignUpFormData(prevState => ({...prevState, code: otp}));
    }, [otp]);


    const postData = async (resend=false) => {
        const headers = {"Content-Type": "application/json"};
        const params = resend ? {resend: "1"} : {};
        const postData = resend ? {...signUpFormData, code: "send"} : signUpFormData;

        function checkResult(res) {
            const errorsData = res?.response?.data?.errors;
            if (errorsData) {
                const newOtpError = errorsData?.code;
                if (newOtpError) {
                    localStorage.removeItem("signUpFormErrors");
                    setOtpError(newOtpError);
                } else {
                    localStorage.setItem("signUpFormErrors", JSON.stringify(errorsData));
                    navigate('/sign-up');
                }
            } else if (res.status === 201) {
                localStorage.removeItem("signUpFormData");
                localStorage.removeItem("signUpFormErrors");
                navigate('/login');
            }
        }

        setOtpError("");
        apiRequest("post", "api_auth/1/customer_signup", postData, headers, false, {
            success: '',
            error: ''
        }, params).then(checkResult).catch(checkResult);
    };

    const handleSubmit = async () => {
        return await postData();
    };

    const handleResend= async () => {
        return await postData(true);
    };

    /* jshint ignore:start*/
    return (
        <div className='d-flex flex-column site-auth-wrapper'>
            <HeaderAuthentication/>
            <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
                <div className="container">
                    <div className="form-wrapper">
                        <div className="form-heading text-center">
                            <h3>Let us know it’s you</h3>
                            <p>To secure your account, enter the code we just send to your email</p>
                        </div>
                        <div className="field-wrapper">
                            <div className="mb__20 position-relative">
                                <input type="text" className="form-control" value={otp} onChange={(e) => setOtp(e.target.value)}
                                       placeholder="Enter Verification Code"/>
                                {otpError !== '' &&
                                    <div className="validation-msg"
                                         onClick={() => setOtpError("")}>{otpError}</div>}
                            </div>

                            <div className="additional-info mb__20 d-flex justify-content-end">
                                <p className='reset-password mb-0'>Didn’t get the code?
                                    <Link to=""
                                          onClick={handleResend}
                                          className='btn-link reset-password'>Resend code</Link></p>
                            </div>
                        </div>
                        <div className="button-wrap mb-0">
                            <button onClick={handleSubmit} className='btn btn-primary' disabled={disabled}>Done</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    /* jshint ignore:end*/

}
