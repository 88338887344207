import React from 'react'
import HeaderDashboard from '../../common/HeaderDashboard'
import SettingNavigation from '../../common/SettingNavigation'
import { Link } from 'react-router-dom'
import Svg from '../../common/Svg'

export default function UserListTable() {
    return (
        <>
            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <SettingNavigation />
                        <div className="flex-grow-1 setting-page-wrapper">

                            <div className="container">
                                <div className="user-permissions ResellersSystemIntegrators devices-storage-wrapper">
                                    <div className="tab-search search-filter d-xl-flex align-items-center">
                                        <div className="flex-grow-1 search-filter-padding theme-form-wrapper pe-0">
                                            <input type="text" className="form-control custom-search-input" placeholder="Search" />
                                        </div>
                                        {/* <div className="right-wrap d-sm-flex align-items-center">
                                            <div className="drop-wrap d-flex align-items-center">
                                                <label>Sort By - </label>
                                                <div className="dropdown ques-options">
                                                    <button className="btn dropdown-tgl" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Recently added
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" href="#"> Recently added </a>
                                                        <a className="dropdown-item" href="#"> Date created </a>
                                                        <a className="dropdown-item" href="#"> Sort A-Z </a>
                                                        <a className="dropdown-item" href="#"> Sort Z-A </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">


                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>User Name</th>
                                                            <th>Access Level</th>
                                                            <th>Last Login</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Super Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Super Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Super Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Super Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Super Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ben Dunne</td>
                                                            <td>Enterprise Super Admin</td>
                                                            <td>29/06/2023</td>
                                                            <td>
                                                                <div className="table-dropdown dropdown">
                                                                    <button className="btn btn-action" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <Svg name='three-dot' />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Edit</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Disable</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Delete</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Reset Password</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item" href="#">Resend Welcome Email</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="button-wrap text-center pt-lg-4 pt-3">
                                <Link to='' className='btn btn-secondary btn-rounded btn-300'>Create User</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
