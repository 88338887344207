import React from 'react'

import { Link } from 'react-router-dom'
import HeaderAuthentication from '../common/HeaderAuthentication'
export default function SignupComplete() {
    return (
        <div className='d-flex flex-column site-auth-wrapper'>
            <HeaderAuthentication />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
                <div className="container">
                    <div className="form-wrapper">
                        <div className="form-heading text-center">
                            <h3>Complete Your Signup</h3>
                            <p>Please fill in the following details to get started</p>
                        </div>
                        <div className="field-wrapper">
                            <div className="mb__20 position-relative"> {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                <input type="text" className="form-control" placeholder="Enter ABN " />
                                {/* <div className="validation-msg">This is not a valid email</div> */}
                            </div>
                            <div className="mb__20 position-relative">
                                <select class="form-select">
                                    <option>Timezone</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>

                            <div className="mb__20 position-relative"> {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                <input type="text" className="form-control" placeholder="Recordging Start Time" />
                                {/* <div className="validation-msg">This is not a valid email</div> */}
                                <div className="form-text-help">If start is after stop fetch between: Start to Midnight otherwise start to Stop. If empty, fetch anytime</div>
                            </div>
                            <div className="mb__20 position-relative"> {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                <input type="text" className="form-control" placeholder="Recordging Stop Time" />
                                {/* <div className="validation-msg">This is not a valid email</div> */}
                                <div className="form-text-help">If Stop is Before Start fetch between: Midnight to stop otherwise Start to Stop. If empty fetch any time</div>
                            </div>
                            <div className="mb__20 position-relative"> {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                <select class="form-select">
                                    <option>Seller</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>

                            </div>

                        </div>
                        <div className="button-wrap mb-0">
                            <Link to="/customer/cameras">
                                <button className='btn btn-primary'>Done</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
