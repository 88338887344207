import React, { useState, useEffect } from 'react';
import { useUiContext } from '../../../contexts/UiContext';
import { formValidation } from '../../../helper/common';
import HeaderDashboard from '../../common/HeaderDashboard';
import SettingNavigation from '../../common/SettingNavigation';
import { getLocal } from '../../../helper/common';
import Select from "react-select";
import { DISTRIBUTOR, DISTRIBUTOR_ADMIN, SELLER, SELLER_ADMIN } from '../../../constants/common';

export default function AccountDetails({route}) {
    const userId = localStorage.getItem("customeruuid");
    const { apiRequest } = useUiContext();
    const [isDisabled, setIsDisabled] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [distributorsData, setDistributorsData] = useState([]);
    const [selectedDistributor, setSelectedDistributor] = useState([])
    let role = getLocal("role");
    const [formData, setFormData] = useState({
        name: '',
        abn: '',
        Timezone: '',
        recordingStartTime: '',
        recordingEndTime: '',
        recordingFetchDescription: '',
        contact_email: '',
    });
    const endpoint = (route === 'customer')?'customers':(route === 'reseller')?'sellers':'distributors';
    useEffect(() => {
        const {name,abn,Timezone,contact_email} = formData;
        formValidation({name,abn,Timezone,contact_email}, setIsDisabled, setEmailError);
    }, [formData]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const descName = "recordingFetchDescription";
        let descValue = formData.recordingFetchDescription;
        if (name === "recordingStartTime" || name === "recordingEndTime") {
            const startTime = name === "recordingStartTime" ? value : formData.recordingStartTime;
            const endTime = name === "recordingEndTime" ? value : formData.recordingEndTime;
            if (!(startTime && endTime && startTime !== endTime)) {
                descValue = "At Any Time";
            } else if (startTime.localeCompare(endTime) === -1) {
                descValue = `Between ${startTime} and ${endTime}`;
            } else {
                descValue = `Between Midnight to ${endTime} and ${startTime} to Midnight`;
            }
        }
        setFormData({ ...formData, [name]: value, [descName]: descValue });
    };
    const getUser = async () => {
        
        const username = localStorage.getItem("username");
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        
        const res = await apiRequest('get', `api/1/${endpoint}/${userId}/`, "", headers,true,{ success: '', error: 'User details not found'});
        if (res && res.data) {
            setFormData(preData => ({
                ...preData,
                abn: res.data.abn,
                name: res.data.name,
                Timezone: res.data.timezone,
                recordingStartTime: res.data.recording_fetch_start_time,
                recordingEndTime: res.data.recording_fetch_stop_time,
                recordingFetchDescription: res.data.recording_fetch_description,
                contact_email: res.data.contact_email,
            }));
            if(role === SELLER_ADMIN){
                if(res.data.distributors.length > 0){
                    let options = [];
                    res.data.distributors.map((ele) => {
                        options.push({
                            value: ele.distributor_uuid,
                            label: ele.name
                        })
                    })
                    setSelectedDistributor(options)
                }
            }
        }
    };

    let updateBody = {
        contact_email: formData.contact_email,
        contact_name: formData.name,
        name: formData.name,
        distributors: selectedDistributor.length > 0 ? selectedDistributor.map((distributor, i) => {
            return {"distributor_uuid": distributor.value}
        }) : [],
    };

    useEffect(()=>{
        const startTime = formData.recordingStartTime;
        const endTime = formData.recordingEndTime;
        if (startTime && endTime && startTime !== endTime) {
            updateBody.recording_fetch_start_time = startTime;
            updateBody.recording_fetch_stop_time = endTime;
        } else {
            updateBody.recording_fetch_start_time = null;
            updateBody.recording_fetch_stop_time = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formData]);

    const update = async (e) => {
        e.preventDefault();
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        const res = await apiRequest('patch', `api/1/${endpoint}/${userId}/`, updateBody, headers, true, { success: 'Profile update Successfully', error: 'Failed to update user details' });
        if (res) {
            await getUser();
        }

    };
    useEffect(() => {
        getUser();
        if (role === SELLER_ADMIN) {
            getDestributors();
        }
    }, []);
    const getDestributors = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        const res = await apiRequest('get', `api/1/distributors/`, "", headers, false, { success: '', error: 'Failed to get distributors list'});
        if (res && res.data && res.data.results) {
            let options = [];
            if (res.data.results.length > 0) {
                res.data.results.map((ele) => {
                    options.push({
                            value: ele.distributor_uuid,
                            label: ele.name,
                        });
                    });
                setDistributorsData(options);
            }
        }
    };
    const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;
    
    return (
        <div className="d-flex flex-column site-main-wrapper setting-body">
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">

                        <div className="container">
                            <form className="profile-wrapper account-details-form">
                                <div className="row theme-form-wrapper">
                                    <div className="col-lg-6">
                                        <div className="account-form timezone-form p-0">
                                            <div className="d-flex mb__20">
                                                <label htmlFor="name" className="col-form-label text-end">Name</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" className="form-control" id="name" name="name" value={formData.name || ""} onChange={handleChange} placeholder='Billing test customer one' />
                                                </div>
                                            </div>
                                            <div className="d-flex mb__20">
                                                <label htmlFor="abn" className="col-form-label text-end">ABN</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" className="form-control" id="abn" name="abn" value={formData.abn || ""} onChange={handleChange} placeholder='34 784 206 473' />
                                                </div>
                                            </div>
                                            <div className="d-flex mb__20">
                                                <label htmlFor="Timezone" className="col-form-label text-end">Timezone</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" className="form-control" id="Timezone" name="Timezone" value={formData.Timezone || ""} onChange={handleChange} placeholder='Australia/Melbourne' disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                   {/* { isAdmin ?  role !== DISTRIBUTOR && role !== DISTRIBUTOR_ADMIN && role !== SELLER_ADMIN && role !== SELLER && <div className="col-lg-6">
                                        <div className="recording-form">
                                            <div className="d-flex mb__20 justify-content-end">
                                                <label htmlFor="recordingStartTime" className="col-form-label text-end">Recording Fetch Start Time</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" className="form-control" id="recordingStartTime" name="recordingStartTime" value={formData.recordingStartTime || ""} onChange={handleChange} />
                                                    <div className="form-text">If Start is after Stop fetch between: Start to Midnight otherwise Start to Stop. If empty, fetch any time</div>
                                                </div>
                                            </div>
                                            <div className="d-flex mb__20 justify-content-end">
                                                <label htmlFor="recordingEndTime" className="col-form-label text-end">Recording Fetch Stop Time</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" className="form-control" id="recordingEndTime" name="recordingEndTime" value={formData.recordingEndTime || ""} onChange={handleChange} />
                                                    <div className="form-text">If Stop is before Start fetch between: Midnight to Stop otherwise Start to Stop. If empty, fetch any time</div>
                                                </div>
                                            </div>
                                            <div className="d-flex mb__20 justify-content-end">
                                                <label htmlFor="recordingFetchDescription" className="col-form-label text-end">Recordings Fetched</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" disabled className="form-control" id="recordingFetchDescription" name="recordingFetchDescription" value={formData.recordingFetchDescription || ""} onChange={handleChange} />
                                                    <div className="form-text">Recording Videos will Copy to Cloud during the saved time(s) if Storage Subscribed. Note: Recording information will sync at any time. </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div> : null } */}

                                    <div className="col-lg-12">
                                        <hr className='custom-margin' />
                                    </div>
                                    {/* <div className="col-lg-6"></div> */}
                                    <div className="col-lg-6">
                                        <div className="recording-form account-form px-0">

                                            {/* <div className="d-flex mb__20 justify-content-end">
                                                <label htmlFor="Phone" className="col-form-label text-end">Phone</label>
                                                <div className="flex-grow-1">
                                                    <input type="text" className="form-control" id="Phone" name="Phone" value={formData.Phone} onChange={handleChange} placeholder='Your phone' />
                                                </div>
                                            </div> */}
                                            <div className="d-flex mb__20">
                                                <label htmlFor="Email" className="col-form-label text-end">Email</label>
                                                <div className="flex-grow-1">
                                                    <input type="email" className="form-control" id="email" name="contact_email" value={formData.contact_email || ""} onChange={handleChange} placeholder='youremail@mail.com' />
                                                </div>
                                            </div>
                                            { role === SELLER_ADMIN &&  
                                            <div className="d-flex mb__20">
                                                <label htmlFor="" className='col-form-label'>Distributors</label>
                                                <div className="flex-grow-1">
                                                    <Select
                                                        isMulti
                                                        className="form-control react-select-container"
                                                        classNamePrefix="react-select"
                                                        name="sitemap_uuid"
                                                        options={distributorsData}
                                                        value={selectedDistributor}
                                                        onChange={(e) => {
                                                            setSelectedDistributor(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    { isAdmin ? <div className="col-12">
                                        <div className="button-wrap pt-5 text-center">
                                            <button className='btn btn-primary btn-rounded' disabled={isDisabled} onClick={update}>Save Changes</button>
                                        </div>
                                    </div> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
