import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LIST_VIEW, baseUrl, ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN } from "../../../constants/common";
import { useUiContext } from "../../../contexts/UiContext";
import HeaderDashboard from "../../common/HeaderDashboard";
import RefreshButton from "../../common/RefreshButton";
import Svg from "../../common/Svg";
import { getLocal, checkUserRoutePermission } from "../../../helper/common";
import moment from "moment-timezone";
import SiteView from "../siteview/SiteView";

export default function Index({ route }) {
  let role = getLocal("role");
  let userRole = getLocal('userRole');
  const { noOfColumns, apiRequest, refreshCount, searchFilter, sortBy, setSortBy } =
    useUiContext();
  const noForBootstrapClass = 12 / noOfColumns;
  const [deviceList, setDeviceList] = useState([]);
  const [activeGroupName, setActiveGroupName] = useState("All");
  const [groups, setGroups] = useState([]);
  const [addressesLatLog, setAddressesLatLog] = useState([]);
  const [addressesNames, setAddressesNames] = useState([]);
  const [groupUuid, setGroupUuid] = useState("");
  const [pageValue, setPageValue] = useState("");

  const [totalPageNo, setTotalPageNo] = useState("");
  const [page, setPage] = useState(1);
  const [delayOver, setDelayOver] = useState(false);
  
  const [authToken, setAuthToken] = useState("");
  const timeZone = localStorage.getItem("tz");
  const [defaultView, setDefaultView] = useState("");

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
  };

  const getTokens = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", `api_auth/1/auth_token`, {}, headers, false)
      .then((res) => {
        if (res.data?.authtoken) {
          // noinspection JSUnresolvedReference
          setAuthToken(res.data);
          return res.data;
        } else {
          console.warn(res.data);
          return authToken;
        }
      })
      .catch((error) => {
        console.error(error);
        return authToken;
      });
  };

  function handlePageValueKeyDown(event) {
    setPageValue(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        setPage(event.target.value);
        break;
    }
  }
  function handlePageValueFocusout(event) {
    setPageValue(event.target.value);
    if (event.target.value) {
      setPage(event.target.value);
    } else {
      setPage(1);
    }
  }

  const getDeviceList = async () => {
    let params = {
      page: page,
    };
    if (groupUuid) params.group_uuid = groupUuid;
    if (searchFilter) params.search = searchFilter;
    if (sortBy) params.ordering = sortBy;
    return await apiRequest(
      "get",
      "api/1/devices/",
      {},
      headers,
      true,
      { success: "", error: "" },
      params
    ).then(async (res) => {
      let devices = res.data.results;
      setTotalPageNo(res.data.page_count);
      setPage(res.data.page);
      if (devices.length > 0) {
        if (groupUuid !== "") {
          try {
            for (let i = 0; i < devices.length > 0; i++) {
              if (devices[i].is_active) {
                let res = await apiRequest(
                  "get",
                  `api/1/devices/${devices[i].device_uuid}/mjpg_img_src`,
                  {},
                  headers,
                  true
                );
                if (res) {
                  let response = res.response.data;
                  devices[i].live_url = response;
                }
              }
            }
          } catch (error) {
            console.log("error: ", error);
          }
        }
      }
      setDeviceList(devices);
    });
  };

  const getGroups = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/groups/", "", headers, false)
      .then((res) => {
        if (res.data?.results !== null) {
          setGroups(res.data.results);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAddressList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest("get", "api/1/addresses/", "", headers, true).then(
      (res) => {
        if (res) {
          let latlogArray = [];
          let siteAdsress = [];
          res.data.results.map((result, index) => {
            latlogArray.push([result.lat, result.lon, result.address_uuid]);
            siteAdsress.push([result.name]);
          });
          setAddressesLatLog(latlogArray);
          setAddressesNames(siteAdsress);
        }
      }
    );
  };

  const getSiteMaps = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/sitemaps/", "", headers, false)
      .then((res) => {
        if (res.data?.results !== null) {
         
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    
    getTokens().then((_) => {
      if (role === "customer" || role === "customerIsAdmin") {
        getDeviceList().then((_) => {});
      }
    });
  }, [refreshCount, groupUuid, searchFilter, sortBy, page]);

  useEffect(() => {
    if (role === "customer" || role === "customerIsAdmin") {
      getGroups().then((_) => {});
      getSiteMaps();
      getAddressList();
    }
  }, []);

  useEffect(() => {
    const savedGroupUuid = localStorage.getItem("groupUuid");
    if (savedGroupUuid) {
      setGroupUuid(savedGroupUuid);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("groupUuid", groupUuid);
  }, [groupUuid]);

  const groupHeaders = () => {
    return (
      <>
        {defaultView === LIST_VIEW && (
          <div className="camera-header d-flex align-items-center justify-content-between">
            <ul className="filter-list flex-grow-1 align-items-center d-none d-lg-flex">
              <li key="all" onClick={() => setGroupUuid("")}>
                <button className={groupUuid === "" ? "active" : ""}>
                  All
                </button>
              </li>
              {groups?.map((group, key) => (
                <li key={key} onClick={() => setGroupUuid(group.group_uuid)}>
                  <button
                    className={groupUuid === group.group_uuid ? "active" : ""}
                  >
                    {group.name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="d-block d-lg-none">
              <div className="dropdown group-dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownSorting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {activeGroupName}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownSorting">
                  <li
                    key="all"
                    onClick={() => {
                      setActiveGroupName("All");
                      setGroupUuid("");
                    }}
                  >
                    <button className="dropdown-item">All</button>
                  </li>
                  {groups?.map((group, key) => (
                    <li
                      key={key}
                      onClick={() => {
                        setActiveGroupName(group.name);
                        setGroupUuid(group.group_uuid);
                      }}
                    >
                      <button className="dropdown-item">{group.name}</button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="d-block d-lg-none text-center">
              <RefreshButton />
            </div>

            <div className="dropdown">
              <button
                className="btnn bg-transparent p-0"
                type="button"
                id="dropdownSorting"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Svg name="dropdown-icon" />
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownSorting">
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => setSortBy("name")}
                  >
                    A To Z
                  </span>
                </li>
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => setSortBy("-name")}
                  >
                    Z to A
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    );
  };
  const isAdmin = localStorage.getItem("isadmin") === "true";

  const Newdevice = () => {
    useEffect(() => {
      const timerId = setTimeout(() => {
        setDelayOver(true);
      }, 2000);

      return () => {
        clearTimeout(timerId);
      };
    }, []);

    if (!delayOver) {
      return null; // or a loading indicator
    }

    return (
      <div className="main-page-height devices-page d-flex align-items-center justify-content-center text-center flex-column">
        <div className="container">
          {checkUserRoutePermission(
            role,
            `/${route}/cameras/add-new-device`
          ) && (
            <Link
              to={`/${route}/cameras/add-new-device`}
              className="add-device"
            >
              <Svg name="add-device" />
              <p>Add device</p>
            </Link>
          )}
        </div>
      </div>
    );
  };

  const changeDefaultView = (view) => {
    setDefaultView(view);
    if (view === LIST_VIEW) {
      getDeviceList()
    }
  };

  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard route={route} changeView={changeDefaultView} />
      <div className="flex-grow-1 main-wrapper">
        {/* empty page */}

        {deviceList?.length <= 0 ? (
          <div className="main-page-height devices-page d-flex flex-column">
            {groupHeaders()}
            <Newdevice />
          </div>
        ) : (
          <div className="main-page-height devices-page d-flex flex-column">
            {groupHeaders()}
            {defaultView === LIST_VIEW ? (
              <div className="flex-grow-1 camera-list-wrapper">
                {/*If Grid is Camera - 3x2*/}
                <div className="camera-row row">
                  {deviceList?.map((device, key) => {
                    // let imgUrl = getImageForDevice(device.device_uuid);
                    return (
                      <div
                        key={key}
                        className={`col-lg-${noForBootstrapClass} mb-4`}
                      >
                        <div className="card-camera">
                          <div className="card-header d-flex">
                            <div className="camera-status">
                              <Svg
                                name={
                                  device.status === "ok"
                                    ? `camera-active`
                                    : "camera-inactive"
                                }
                              />
                            </div>
                            <div className="camera-details flex-grow-1 d-flex align-items-center">
                              <div className="flex-grow-1 camera-name">
                                {device.name}{" "}
                              </div>

                              <div className="dropdown notification-dropown ">
                                <button
                                  className={`btn-notif ${
                                    device.recordings_unacknowledged_count > 0
                                      ? "active"
                                      : ""
                                  }`}
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <Svg name="notification" />
                                </button>
                                <div
                                  className="dropdown-menu dropdown-menu-end"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <div className="notif-header">
                                    <a href="#">Mark all as read</a>
                                  </div>
                                  <ul>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <span className="me-2">
                                          {device.last_connected
                                            ? moment
                                                .tz(
                                                  device.last_connected,
                                                  timeZone
                                                )
                                                .format("DD/MM/YYYY HH:mm a")
                                            : ""}
                                        </span>
                                        <span>{device.device_uuid}</span>
                                      </a>
                                    </li>
                                  </ul>
                                  {checkUserRoutePermission(
                                    role,
                                    `/${route}/cameras/notifications/:device_uuid`
                                  ) && (
                                    <div className="notification-footer">
                                      <Link
                                        to={`/${route}/cameras/notifications/${device.device_uuid}`}
                                      >
                                        <button className="btn btn-primary btn-rounded">
                                          View All
                                        </button>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {[ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(userRole) && (
                                <Link
                                  to={`/${route}/cameras/details/${device.device_uuid}`}
                                  className="btn-notif ms-1 details-btn"
                                >
                                  <Svg name="setting" />
                                </Link>
                                )
                              }
                            </div>
                          </div>
                          {checkUserRoutePermission(
                            role,
                            `/${route}/cameras/live-camera/:device_uuid`
                          ) && (
                            <Link
                              to={`/${route}/cameras/live-camera/${device.device_uuid}`}
                            >
                              {device.is_subscribed &&
                              device.is_active &&
                              device.status === "ok" ? (
                                <div className="card-camera-body">
                                  {groupUuid === "" ? (
                                    <img
                                      src={`${baseUrl}api/1/devices/${device.device_uuid}/snapshot_img/?as_attachment=true&authtoken=${authToken?.authtoken?.token}`}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={device.live_url}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="card-camera-body no-subscriptions novideo">
                                  <div className="bg-danger text-white alert alert-primary">
                                    Offline: {device.device_description}
                                  </div>
                                </div>
                              )}
                            </Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-md-flex flex-wrap justify-content-center page-nav">
                  {!deviceList.length > 0 ||
                    (page > 1 && (
                      <div className="text-center pt-2 mx-2 ">
                        <Link
                          to={""}
                          className="btn btn-primary btn-rounded"
                          onClick={() => setPage(page - 1)}
                        >
                          Previous Page
                        </Link>
                      </div>
                    ))}
                  {!deviceList.length > 0 ||
                    (page < totalPageNo && (
                      <div className="text-center pt-2 mx-2 ">
                        <Link
                          to={""}
                          className="btn btn-primary btn-rounded"
                          onClick={() => setPage(page + 1)}
                        >
                          Next Page
                        </Link>
                      </div>
                    ))}
                  {!deviceList.length > 0 ||
                    (page <= totalPageNo && (
                      <div className="pagination-btn text-center pt-2 mx-2">
                        <p className="no-underline heading">
                          Page {page} of {totalPageNo}
                        </p>
                      </div>
                    ))}
                  {!deviceList.length > 0 ||
                    (page <= totalPageNo && (
                      <div className="d-flex pt-2 mx-2">
                        <p className="pt-3 mx-3 no-underline heading">
                          Go to Page#
                        </p>
                        <input
                          className="page-search form-control mr-sm-2"
                          type="text"
                          id="gotopage"
                          placeholder="Search"
                          value={pageValue}
                          onChange={(e) => {
                            setPageValue(e.target.value);
                          }}
                          onKeyDown={handlePageValueKeyDown}
                          onBlur={handlePageValueFocusout}
                          aria-label="Search"
                        />
                        <button
                          className="search-btn btn btn-primary btn-rounded my-2 my-sm-0"
                          disabled={pageValue === ""}
                          onClick={() => {
                            setPage(pageValue);
                          }}
                        >
                          Go
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <>
                {addressesLatLog.length > 0 && (
                  <SiteView
                    latlogArray={addressesLatLog}
                    addressesNames={addressesNames}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
