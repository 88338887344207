import React, { useState, useEffect } from 'react';
import DetailsSubmenu from '../../common/DetailsSubmenu';
import HeaderDashboard from '../../common/HeaderDashboard';
import RecordingDetailsSidebar from '../../common/RecordingDetailsSidebar';
import Svg from '../../common/Svg';
import Logo from '../../../assets/images/eyefi_logo-opcacity.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useUiContext } from '../../../contexts/UiContext';
import { baseUrl } from '../../../constants/common';
import moment from 'moment-timezone';
// import SampleVideo from '../../../assets/images/sample-video.mp4';


export default function Notifications({ route }) {
    const navigate = useNavigate();
    const [notification, setNotification] = useState();
    const [recording_uuid, setRecordingUuid] = useState("");
    const { device_uuid } = useParams();
    const { apiRequest } = useUiContext();
    const [recordingDetails, setRecordingDetails] = useState([]);
    const [authToken, setAuthToken] = useState('');
    const timeZone = localStorage.getItem("tz");
  const [isDeletetingDevice, setIsDeletetingDevice] = useState(false);



    useEffect(() => {
        if (device_uuid) {
            getNotification(device_uuid);
        }
    }, [device_uuid]);

    const getNotification = async (device_uuid) => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        return await apiRequest("get", `api/1/recordings`, {}, headers, true, {success: '', error: ''},
            {device_uuid: device_uuid, page: 1}
        ).then(res => {
            if (res.data?.results) setNotification(res.data.results);
            else console.warn(res.data);
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleIdData = (recording_uuid) => {
        setRecordingUuid(recording_uuid);
    };

    const getTokens = async () => {
        const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
        };
        return await apiRequest(
            "get",
            `api_auth/1/auth_token`,
            {},
            headers,
            false
        ).then(res => {
            if (res.data?.authtoken) {
                // noinspection JSUnresolvedReference
                setAuthToken(res.data);
                return res.data;
            } else {
                console.warn(res.data);
                return authToken;
            }
        }).catch((error) => {
            console.error(error);
            return authToken;
        });
    };

    const deleteDevice = async () => {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
        };
        setIsDeletetingDevice(true);
        await apiRequest(
          "delete",
          `api/1/devices/${device_uuid}`,
          {},
          headers,
          true,
          { success: "Device deleted successfully", error: "", showToast: true }
        )
          .then((res) => {
            navigate("/customer/cameras");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setIsDeletetingDevice(false);
          });
      };

    const getRecordingDetails = async (recording_uuid) => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        return await apiRequest("get", `api/1/recordings/${recording_uuid}`, "", headers, true).then(res => {
            setRecordingDetails(res.data);
        });
    };
    useEffect(() => {
        let promises = [
            getTokens(),
        ];
        if (recording_uuid !== '') {
            promises.push(getRecordingDetails(recording_uuid));
        }
        return () => Promise.all(promises);
    }, [recording_uuid]);

    const gettime = (starttime, endtime) => {
        return(
        Math.floor((new Date(endtime).getTime() / 1000 - new Date(starttime).getTime() / 1000) / 60) + ":" +  ('0' +Math.floor((new Date(endtime).getTime() / 1000 - new Date(starttime).getTime() / 1000) % 60)).slice(-2));
    };
    return (
        <div className='d-flex flex-column site-main-wrapper grid-page'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">

                {/* listing page */}

                <div className="main-page-height devices-page d-flex">
                    <RecordingDetailsSidebar route={route} />
                    <div className="flex-grow-1">
                        <div className="d-flex flex-column">
                            <div className="camera-header d-flex align-items-center details-header">
                                <div className="dropdown">
                                    <button className="btnn" type="button" id="dropdownSorting" data-bs-toggle="dropdown" aria-expanded="false">
                                        <Svg name="camera-active" />
                                    </button>

                                </div>
                                <div className="flex-grow-1 available-heading">
                                    {/* <h2>{notification?.device.name}</h2> */}
                                </div>
                                <div className="button-wrap">

                                    <button className='btn btn-delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                        <Svg name="delete-icon" />
                                    </button>

                                </div>
                            </div>
                            <div className="flex-grow-1 details-view">
                                <DetailsSubmenu route={route} />

                                <div className="notification-view-page">
                                    <div className="row gx-0">

                                        <div className="col-xl-6">
                                            {notification?.map((res, index) => (
                                                <div className="notification-list add-new-device-page" key={index} onClick={() => handleIdData(res?.recording_uuid)}>
                                                    <div className="notification-wrapper d-flex">
                                                        <div className="timestamp">{res?.acknowledged_time
                                                            ? moment.tz(res?.acknowledged_time, timeZone
                                                            ).format("DD/MM/YYYY HH:mm a")
                                                            : ""}</div>
                                                        <div className="flex-grow-1 text">{res?.event_id}</div>
                                                        <div className="timecount">{gettime(res?.start_time, res?.stop_time)}</div>
                                                    </div>

                                                </div>))}
                                        </div>
                                        <div className="col-xl-6 d-none d-xl-block">
                                            {/* <div className="video-wrap d-flex align-items-center justify-content-center">
                                                <img src={Logo} alt="" className='img-fluid' />
                                            </div>
                                             */}

                                            <div className="video-wrap">
                                                {recordingDetails.snapshot_file_exists ?
                                                    <div className="video-section">
                                                        {recording_uuid !== '' &&
                                                            <video width="1850" height="700" src={`${baseUrl}api/1/recordings/${recording_uuid}/video/?as_attachment=true&authtoken=${authToken?.authtoken?.token}`} controls  >
                                                            </video>}
                                                    </div>
                                                    :
                                                    <div className="card-camera-body no-subscriptions">
                                                        {recordingDetails.snapshot_description && <div className="alert alert-primary">{recordingDetails.snapshot_description}</div>}
                                                    </div>
                                                }
                                                {/* <div className="text-wrap">
                                                    <div className="text-inner">
                                                        <div className="row gx-0">
                                                            <div className="col-lg-6">
                                                                <div className="d-flex title">
                                                                    <div className="icon">
                                                                        <Svg name="play-icon" />
                                                                    </div>
                                                                    <p className='m-0'>{notification?.event_id}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="d-flex title">
                                                                    <div className="icon">
                                                                        <Svg name="time-icon" />
                                                                    </div>
                                                                    <p className='m-0'>{notification?.acknowledged_time}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="download-button text-end">
                                                        <button className='download-btn'>
                                                            Download <Svg name="download-icon" />
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade modal-delete" id="deleteModal" tabIndex={-1} aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                    <div className="text-wrap">
                        <h4>Are you sure you want to delete this device? </h4>
                        <div className="note">
                        <div className="d-flex heading">
                            <Svg name="delete-modal-icon" />
                            <div className="flex-grow-1 ps-3">Warning</div>
                        </div>
                        <p>All logs for this device will also be deleted.</p>
                        </div>
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                        <button
                        type="button"
                        className="btn btn-delete-new"
                        data-bs-dismiss="modal"
                        onClick={() => {
                            deleteDevice();
                        }}
                        >
                        {isDeletetingDevice ? (
                            <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            "Delete"
                        )}
                        </button>
                        <button
                        type="button"
                        className="btn btn-cancel"
                        data-bs-dismiss="modal"
                        >
                        Cancel
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
