export const baseUrl = "/";
export const tileAttributionHtml = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export const tileUrl = baseUrl + "osm/{z}/{x}/{y}.png" ;
// export const osmTileUrl =   "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
export const SITE_VIEW = "SITE_VIEW";
export const LIST_VIEW = "LIST_VIEW";
export const SINGLE = 'single';
export const QUAD = 'quad';
export const CUSTOMER = "customer";
export const DISTRIBUTOR = "distributor";
export const SELLER = "seller";
export const CUSTOMER_ADMIN = "customerIsAdmin";
export const DISTRIBUTOR_ADMIN = "distributorIsAdmin";
export const SELLER_ADMIN = "sellerIsAdmin";
export const ENTERPRISE_ADMIN = "enterprise_admin";
export const ENTERPRISE_SUPER_USER = "enterprise_super_user";
export const ENTERPRISE_USER = "enterprise_user";
export const MULTI_SITE_ADMIN = "multi_site_admin";
export const MULTI_SITE_SUPER_USER = "multi_site_super_user";
export const MULTI_SITE_USER = "multi_site_user";
export const SINGLE_SITE_ADMIN = "single_site_admin";
export const SINGLE_SITE_SUPER_USER = "single_site_super_user";
export const SINGLE_SITE_USER = "single_site_user";

