import React from "react";

function FullScreenModal({isOpen, children}) {
  return (
    <div className="modal full-screen-modal" style={isOpen === false ? { display: "none" } : { display: "block" }}>
      <div className="modal__wrapper" style={{ width: window.innerWidth, height: window.innerHeight }}>
        {children}
      </div>
    </div>
  );
}

export default FullScreenModal;
