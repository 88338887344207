import React from 'react'
import Svg from './Svg'

export default function RefreshButton() {
    const handleClicks = event => {
        event.currentTarget.classList.add('loading');
        setTimeout(() => {
            const refresh = document.querySelector('.refresh-btn');
            refresh.classList.remove('loading');
        }, 1000);
    }
    return (
        <button id="refresh" className="refresh-btn" onClick={handleClicks}> <Svg name="refresh" /> Refresh</button>
    )
}
