import React, { useState } from "react";

const Knob = (props) => {
  const convertRange = (oldMin, oldMax, newMin, newMax, oldValue) => {
    return (
      ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin
    );
  };

  const fullAngle = props.degrees;
  const startAngle = (360 - props.degrees) / 2;
  const endAngle = startAngle + props.degrees;
  const margin = props.size * 0.15;
  const [deg, setDeg] = useState(
    Math.floor(
      convertRange(props.min, props.max, startAngle, endAngle, props.value)
    )
  );

  const startDrag = (e) => {
    e.preventDefault();
    const knob = e.target.getBoundingClientRect();
    const pts = {
      x: knob.left + knob.width / 2,
      y: knob.top + knob.height / 2,
    };
    const moveHandler = (e) => {
      e.preventDefault();
      let currentDeg = getDeg(
        e.clientX || e.touches[0].clientX,
        e.clientY || e.touches[0].clientY,
        pts
      );
      if (currentDeg === startAngle) currentDeg--;
      const newValue = Math.floor(
        convertRange(startAngle, endAngle, props.min, props.max, currentDeg)
      );
      setDeg(currentDeg);
      props.onChange(newValue);
      props.onTouchStart(newValue);
    };
  
    const endHandler = () => {
      document.removeEventListener("mousemove", moveHandler);
      document.removeEventListener("touchmove", moveHandler);
      document.removeEventListener("mouseup", endHandler);
      document.removeEventListener("touchend", endHandler);
    };
  
    document.addEventListener("mousemove", moveHandler);
    document.addEventListener("touchmove", moveHandler, { passive: false }); // Pass `{ passive: false }` to disable passive behavior
    document.addEventListener("mouseup", endHandler);
    document.addEventListener("touchend", endHandler);
  };
  

  const getDeg = (cX, cY, pts) => {
    const x = cX - pts.x;
    const y = cY - pts.y;
    let deg = (Math.atan(y / x) * 180) / Math.PI;
    if ((x < 0 && y >= 0) || (x < 0 && y < 0)) {
      deg += 90;
    } else {
      deg += 270;
    }
    let finalDeg = Math.min(Math.max(startAngle, deg), endAngle);
    return finalDeg;
  };

  const renderTicks = () => {
    let ticks = [];
    const incr = fullAngle / props.numTicks;
    const size = margin + props.size / 2;
    for (let deg = startAngle; deg <= endAngle; deg += incr) {
      const tick = {
        deg: deg,
        tickStyle: {
          height: size + 10,
          left: size - 1,
          top: size + 2,
          transform: "rotate(" + deg + "deg)",
          transformOrigin: "top",
        },
      };
      ticks.push(tick);
    }
    return ticks;
  };

  const dcpy = (o) => {
    console.log("o", o);
    return JSON.parse(JSON.stringify(o));
  };

  let kStyle = {
    width: props.size,
    height: props.size,
  };
  let iStyle = dcpy(kStyle);
  let oStyle = dcpy(kStyle);
  oStyle.margin = margin;
  if (props.color) {
    oStyle.backgroundImage =
      "radial-gradient(100% 70%,hsl(210, " +
      deg +
      "%, " +
      deg / 5 +
      "%),hsl(" +
      Math.random() * 100 +
      ",20%," +
      deg / 36 +
      "%))";
  }
  iStyle.transform = "rotate(" + props.value + "deg)";

  return (
    <div className="knob" style={kStyle}>
      <div className="ticks">
        {props.numTicks
          ? renderTicks().map((tick, i) => (
              <div
                key={i}
                className={"tick" + (tick.deg <= deg ? " active" : "")}
                style={tick.tickStyle}
              />
            ))
          : null}
        {props.value > 0 &&
          renderTicks().map((tick, i) => (
            <div
              key={i}
              className={"tick" + (tick.deg <= props.value ? " active" : "")}
              style={tick.tickStyle}
            />
          ))}
      </div>
      <div className="knob outer" style={oStyle} onMouseDown={startDrag} onTouchStart={startDrag}>
        <div className="knob inner" style={iStyle}>
          <div className="grip" />
        </div>
      </div>
    </div>
  );
};

Knob.defaultProps = {
  size: 50,
  min: 10,
  max: 30,
  numTicks: 0,
  degrees: 270,
  value: 0,
};

export default Knob;
