import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUiContext } from '../../../../contexts/UiContext';
import { formValidation, getLocal, checkUserRoutePermission } from '../../../../helper/common';
import HeaderDashboard from '../../../common/HeaderDashboard'
import SettingNavigation from '../../../common/SettingNavigation'
import Svg from '../../../common/Svg'
import { toast } from 'react-hot-toast';
import { SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER, SINGLE_SITE_USER } from '../../../../constants/common';

export default function Adduser({ route }) {
    let role = getLocal("role")
    let userRole = getLocal('userRole');
    const { apiRequest } = useUiContext();
    let navigate = useNavigate();
    var uid = localStorage.getItem("customeruuid")
    const [isDisabled, setIsDisabled] = useState(false)
    const endpoint = (route === 'customer') ? 'users' : (route === 'reseller') ? 'seller-users' : (route === 'distributor') ? 'distributor-users' : '';
    const profile = (route === 'customer') ? 'customer' : (route === 'reseller') ? 'seller' : (route === 'distributor') ? 'distributor' : '';
    const [firstNameError, setFirstNameError] = useState(false)
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('')
    const [lastNameError, setLastNameError] = useState(false)
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('')
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [roleError, setRoleError] = useState(false)
    const [accessLevelList, setAccessLevelList] = useState({})
    const [accessLevel, setAccessLevel] = useState('')
    const [roleAddress, setRoleAddress] = useState([])
    const [selectedRoleAddress, setSelectedRoleAddress] = useState([])
    const [formData, setFormData] = useState({
        email: '',
        addresses: [],
        is_customer_admin: false,
        role:'',
        first_name: '',
        last_name: '',
    })
    const [emailNotificationTypes, setEmailNotificationTypes] = useState([
        "obj-detect", "Line crossed", "Loitering", "Motion-detect" 
     ])
    const body = {
        [`${profile}_profile`]: {
            [`${profile}_uuid`]: uid,
            [`is_${profile}_admin`]: formData.is_customer_admin,
            [`role`]: accessLevel,
            [`addresses`]: formData.addresses
        },
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name
    }
    useEffect(() => {
        let { first_name, last_name } = formData;
        formValidation({ firstname: first_name, lastName: last_name }, setIsDisabled, setEmailError)
    }, [formData])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const createUser = async (e) => {
        e.preventDefault();
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('post', `api/1/${endpoint}/`, body, headers, true, { success: 'created Successfully', error: 'User not created' })
        if (res) {
            if (res.status === 201) {
                setFormData({
                    is_customer_admin: false,
                    email: '',
                    first_name: '',
                    last_name: '',
                })
                navigate(`/${route}/users-and-permissions`)
            } else if(res.response.status === 400){
                let data = res.response.data;
                if(data){
                    if(data?.first_name && data.first_name.length > 0){
                        setFirstNameError(true);
                        setFirstNameErrorMessage(data.first_name[0]) 
                    }
                    if(data?.last_name && data.last_name.length > 0){
                        setLastNameError(true);
                        setLastNameErrorMessage(data.last_name[0])
                    }
                    if(data?.email && data.email.length > 0){
                        setEmailError(true);
                        setEmailErrorMessage(data.email[0])
                    }
                    if(data.customer_profile?.role && data.customer_profile?.role.length > 0){
                        setRoleError(true)
                    }
                }
            }
        }
    }

    const getUserRoleAddress = async (accessLevel) => {
        let addresses = [];
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        await apiRequest('get', 'api/1/my-user/', {}, headers, false, {})
        .then((res) => {
            let response = res.data;
            if(response) {
                addresses = response.results[0].customer_profile.role_addresses;
                setRoleAddress(addresses);
            }
        }).catch((error) => {
            console.log('error: ',error)
        })            
    }

    const getAccessLevel = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        await apiRequest('get', `api_auth/1/form_choices`, {}, headers, false, {})
        .then((res) => {
            if(res){
                let response = res.data;
                let rolesPriority = response.user_customer_profile_role_admin_priorities;
                let priority = rolesPriority[userRole];
                const filteredData = Object.fromEntries(
                    Object.entries(rolesPriority).filter(([key, value]) => value <=  priority)
                );
                setAccessLevelList(filteredData)
            }
        })
        .catch((error) => {
            console.log('error: ',error)
        })
    }

    useEffect(() => {
        getAccessLevel();
        getUserRoleAddress();
    }, [])

    useEffect(() => {
      setRoleAddress(roleAddress)
    }, [roleAddress])

    const handleCheckboxChange = (index, value, checked,address) => {
        if (checked) {
            if(accessLevel === SINGLE_SITE_ADMIN ||
                accessLevel === SINGLE_SITE_SUPER_USER ||
                    accessLevel === SINGLE_SITE_USER){
                        setSelectedRoleAddress([address])
                    } else {
                        setSelectedRoleAddress([...selectedRoleAddress, address])
                    }
        } else {
            let obj1 = [...selectedRoleAddress] 
            obj1.splice(obj1.indexOf(address),1);
            setSelectedRoleAddress(obj1)
        }
    }

    useEffect(() => {
        setFormData(formData);
    }, [formData])
    

    useEffect(() => {
        setFormData({
            ...formData,
            addresses: selectedRoleAddress,
        })
    }, [selectedRoleAddress])

    useEffect(() => {
        setSelectedRoleAddress(selectedRoleAddress)
        setFormData({ ...formData, addresses: selectedRoleAddress })
    }, [selectedRoleAddress])
    
    useEffect(() => {
        setAccessLevel(accessLevel)
        setSelectedRoleAddress([])
        setFormData({ ...formData, role: accessLevel})
    }, [accessLevel])
    
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            <div className="user-permissions">
                                {checkUserRoutePermission(role, `/${route}/users-and-permissions`) &&
                                    <Link to={`/${route}/users-and-permissions`} className="back-btn d-inline-flex align-items-center">
                                        <Svg name="left-arrow" /> Back
                                    </Link>}
                                <div className="create-user">
                                    <div className="page-heading">
                                        <h3>Create new user</h3>
                                    </div>
                                        <>
                                            <div className="create-user-wrapper">
                                                <div className="form-wrapper theme-form-wrapper">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <input type="text" placeholder="FirstName" className="form-control" value={formData.first_name} onChange={handleChange} id="FirstName" name="first_name" />
                                                                    { firstNameError && <div className="validation-msg" style={{top: 'calc(100% - 10rem)'}} onClick={() => {setFirstNameError(false)}}>{firstNameErrorMessage} </div>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <input type="email" placeholder="Email Address" className="form-control" value={formData.email} onChange={handleChange} id="email" name="email" />
                                                                    { emailError && <div className="validation-msg" style={{top: 'calc(100% - 12.5rem)'}} onClick={() => {setEmailError(false)}}>{emailErrorMessage} </div>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <input type="text" placeholder="Last name" className="form-control" value={formData.last_name} onChange={handleChange} id="lastname" name="last_name" />
                                                                    { lastNameError && <div className="validation-msg" style={{top: 'calc(100% - 6rem)'}} onClick={() => {setLastNameError(false)}}>{lastNameErrorMessage} </div> }
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                            {route === 'customer' &&
                                                                <div className="mb-3">
                                                                    <select 
                                                                        className="form-control text-capitalize"
                                                                        onChange={(e) => {
                                                                            setAccessLevel(e.target.value)
                                                                        }}>
                                                                        <option value="">Select User Access Level</option>
                                                                        { Object.entries(accessLevelList).map((access, i) => {
                                                                            return <option key={i} value={access[0]}>
                                                                                {access[0].replace(/_/g,' ')}
                                                                            </option>
                                                                          })
                                                                        }
                                                                    </select>
                                                                    { roleError && <div className="validation-msg" style={{top: 'calc(100% - 8.5rem)'}} onClick={() => {setRoleError(false)}}>Please select role </div>}
                                                                </div>
                                                            }
                                                            </div>
                                                        </div>
                                                        { route !== 'customer' &&
                                                            <div className="mb-3 form-check">
                                                                <input type="checkbox" className="form-check-input" id="makeAdmin" name="makeAdmin" checked={formData.is_customer_admin} onChange={(e) => setFormData({ ...formData, is_customer_admin: e.target.checked })} />
                                                                <label className="form-check-label" htmlFor="makeAdmin">Make this user Admin</label>
                                                            </div>
                                                        }


                                                        <div className="button-wrap">
                                                            <button className="btn btn-primary btn-rounded" 
                                                                disabled={isDisabled} 
                                                                onClick={(e) => {createUser(e)}}
                                                                >Create
                                                            </button>
                                                        </div>


                                                    </form>
                                                </div>
                                            </div>
                                            {roleAddress.length > 0 && 
                                                <div className="create-user-wrapper mt-3 site-accesible">
                                                    <div className="heading mb-3">
                                                        <h4>Site Accessible by this user</h4>
                                                    </div>
                                                    <div className="check-list-wrapper">
                                                            {roleAddress.map((address, i) => {
                                                                return(<div className="mb-2 form-check" key={i}>
                                                                        <input type="checkbox" className="form-check-input" id={`site${i}`} name="SitesList"
                                                                            value={address.name}
                                                                            checked={selectedRoleAddress.some(e => e.address_uuid === address.address_uuid)}
                                                                            onChange={(e) => {
                                                                                handleCheckboxChange(i, e.target.value, e.target.checked, address)}}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`site${i}`}>{address.name}</label>
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="create-user-wrapper mt-3 site-accesible row">
                                                <div className="heading mb-3">
                                                    <h4>Email Notification Selection</h4>
                                                </div>
                                                <div className="check-list-wrapper col-6 d-flex flex-wrap">
                                                    {emailNotificationTypes.map((type, typeIndex) => {
                                                        return(<div className="mb-2 form-check col-6" key={typeIndex}>
                                                                <input type="checkbox" className="form-check-input" id={`email-notiofication${typeIndex}`} name="SitesList"
                                                                    value={type}                                                            
                                                                />
                                                                <label className="form-check-label" htmlFor={`email-notiofication${typeIndex}`}>{type}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div> */}
                                        </> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
