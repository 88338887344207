import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUiContext } from '../../../contexts/UiContext'
import HeaderDashboard from '../../common/HeaderDashboard'
import SettingNavigation from '../../common/SettingNavigation'
import Svg from '../../common/Svg'
import { formValidation, getLocal } from '../../../helper/common'
import AsyncSelect from 'react-select/async';

export default function AssignSubscription({ route }) {
    let navigate = useNavigate()
    let uid = localStorage.getItem("customeruuid");
    const { apiRequest } = useUiContext();
    const [uniqueProductCodes, setUniqueProductCodes] = useState([]);
    const [loginUserData, setLoginUserData] = useState([]);
    const [data, setData] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [userId, setUserId] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0)
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const bodyDynamic = (route === 'reseller') ? 'customer' : 'seller'
    const endpoint = (route === 'customer') ? 'customers' : (route === 'reseller') ? 'sellers' : 'distributors'
    // const profile = (route === 'reseller' || route === 'distributor') ? 'customers' : 'sellers'
    const queryID = (route === 'customer') ? 'customer_uuid' : (route === 'reseller') ? 'seller_uuid' : 'distributor_uuid'
    const [formData, setFormData] = useState({
        count: "",
        subscription: "",
        productType: "",
        id: "",
        customerId: '',
        customerOrderRef: '',
        sellerOrderRef: ''
    })
    let body = {
        "product": {
            "product_code": formData.productType
        },
        "subscription_count": formData.subscription,
        "seller": {
            "seller_uuid": formData.id
        },
        "seller_order_reference":formData. sellerOrderRef,
        "customer": { "customer_uuid": formData.customerId },
        "customer_order_reference":formData.customerOrderRef,
    }
    const getProductData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/products/`, "", headers, true);
        if (res && res.data) {
            setPageCount(res.data.page_count);
            let resultArray = res.data.results;
            // let unique = [...new Set(resultArray.map(item => item.product_code))];
            setUniqueProductCodes(resultArray)
        }
    }

    const getCustomerData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        await apiRequest('get', `api_auth/1/form_choices`, {}, headers, true).then((res) => {
            if(res.status === 200){
                setCustomers(res.data.user_customers);
                if(route === 'distributor') setUserId(res.data.user_sellers);
            }
        })
    }

    const assignSubs = async () => {
        if(route === 'reseller'){
            body = {
                "product": { "product_code": formData.productType },
                "subscription_count": formData.subscription,
                "customer": { "customer_uuid": formData.customerId },
                "customer_order_reference": formData.customerOrderRef,
            }
        }
        if(route === 'distributor' && formData.customerId === ''){
            for(var property in body){
                if(property === 'customer' && body['customer']['customer_uuid'] === ''){
                    delete body[property]
                }
            }
        }
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('post', `api/1/${endpoint}/${uid}/assign_subscriptions/`, body, headers, true, { success: 'Assigned Subscription succesfully', error: 'Subscription not assigned' });
        if (res) {
            if (res.status == 200) {
                setPage(1);
                setOptions([])
                setUniqueProductCodes([]);
                setFormData({
                    count: "",
                    subscription: "",
                    productType: "",
                    id: "",
                    customerId: '',
                    customerOrderRef: '',
                    sellerOrderRef: ''
                })
                navigate(`/${route}/unassigned-subscriptions-summary`);
            }
        }
    }

    const getUserData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/${endpoint}/${uid}/`, '', headers, true,);
        if (res) {
            setLoginUserData(res.data)
        }
    }

    const getSummary = async (route) => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        if (route === "reseller") {
            const res = await apiRequest('get', `api/1/${endpoint}/${uid}/get_summary_subscriptions_active_for/`, "", headers, true);
            if (res) {
                setData(res.data.rows)
            }
        } else {
            const res = await apiRequest('get', `api/1/${endpoint}/${uid}/get_summary_subscriptions_not_activated/`, "", headers, true);
            if (res) {
                setData(res.data.rows)
            }
        }
    }

    useEffect(() => {
        getProductData();
        getCustomerData();
        getUserData();
        getSummary(route);
    }, [route]);

    useEffect(() => {
        const {subscription, productType, customerId,id} = formData;
        if(route === 'reseller'){
            formValidation({productType, subscription, customerId}, setDisabled);
        }else{
            formValidation({productType, subscription, id}, setDisabled);
        }
    }, [formData])

    useEffect(() => {
        setPage(page);
        if(page <= pageCount && page !== 1){
            setIsLoading(true)
            fetchProductOptions(page).then((res) => {
                setOptions(options.concat(res));
                setIsLoading(false)
            })
        }
    }, [page, pageCount])

    const handleMenuScrollToBottom = async () => {
        setPage(page + 1);   
    };    

    useEffect(() => {
        fetchProductOptions(page).then((options) => { setOptions(options) });
    }, []);

    async function fetchProductOptions(page) {
        const response = await apiRequest('get', `api/1/products/?page=${page}`, "", null, false);
        if (response && response.data) {
            setPageCount(response.data.page_count);
            setUniqueProductCodes(uniqueProductCodes.concat(response.data.results))

            return response.data.results.map((product) => ({
                label: route === "distributor" ? 
                    product.product_code + "(" + product.seller_unassigned_subscriptions_count + ")" :
                        product.product_code + "(" + product.customer_unassigned_subscriptions_count + ")",
                value: product.product_code })
            );
        }
    }

    return (
        <>
            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <SettingNavigation />
                        <div className="flex-grow-1 setting-page-wrapper">
                            <div className="container">
                                <div className="user-permissions">
                                    <Link to={`/${route}/unassigned-subscriptions-summary`} className="back-btn d-inline-flex align-items-center">
                                        <Svg name="left-arrow" /> Back
                                    </Link>
                                    <div className="create-user">
                                        {loginUserData.name != undefined && loginUserData.abn !== undefined &&
                                            <div className="page-heading">
                                                <h3>Assign Subscriptions - {route === "reseller" ? "Seller" : "Distributor"}: <small>{loginUserData.name + "(" + loginUserData.abn + ")"}</small></h3>
                                            </div>}
                                        <div className="create-user-wrapper">
                                            <div className="form-wrapper theme-form-wrapper">

                                                <div className="mb__20">
                                                    <label className='col-form-label'>Product</label>
                                                    <AsyncSelect
                                                        styles={{padding: '13px'}}
                                                        className="form-control react-select-container"
                                                        classNamePrefix="react-select"
                                                        defaultOptions={options}
                                                        options={options}
                                                        isSearchable={false}
                                                        isLoading={isLoading}
                                                        onMenuScrollToBottom={handleMenuScrollToBottom}
                                                        onChange={(e) => {
                                                            let product = uniqueProductCodes?.filter((prd) => prd.product_code === e.value);
                                                            product.length > 0 ? (
                                                                route === "distributor" ?
                                                                    setFormData({ 
                                                                        ...formData, 
                                                                        productType: product[0].product_code, 
                                                                        count: product[0].seller_unassigned_subscriptions_count }) : 
                                                                    setFormData({ 
                                                                        ...formData, 
                                                                        productType: product[0].product_code, 
                                                                        count: product[0].customer_unassigned_subscriptions_count 
                                                                    })
                                                            ) :
                                                            setFormData({
                                                                ...formData,
                                                                productType: "",
                                                                count: ""
                                                            })}
                                                        }
                                                    />
                                                </div>
                                                <div className="mb__20">
                                                    <label className='col-form-label'>Subscription count</label>
                                                    <input type="number" min="1" max={formData.count} className="form-control" placeholder='' onChange={(e) =>
                                                        setFormData({ 
                                                            ...formData, 
                                                            subscription: e.target.value > formData.count ? formData.count : e.target.value  
                                                        })}
                                                        value={formData.subscription} 
                                                    />
                                                    <span className="form-text mt-1">Minimum: 1 Maximum: {formData.count ? formData.count : "0"}</span>
                                                </div>

                                                
                                                {route === 'distributor' && 
                                                    <>
                                                        <div className="mb__20">
                                                            <label className='col-form-label'>Seller</label>
                                                            <select className="form-control"
                                                                onChange={(e) =>
                                                                    setFormData({
                                                                        ...formData, 
                                                                        id: e.target.value === '---' ? '' : e.target.value })
                                                                }
                                                                value={formData.id}>
                                                                <option defaultValue>---</option>
                                                                {userId?.map((res, index) => (
                                                                    <option value={res.seller_uuid} key={index}>{(res.seller_uuid) ? res.name + "(" + res.abn + ")"
                                                                        : res.name + "(" + res.abn + ")"}</option>
                                                                ))}
                                                            </select>
                                                            <span className="form-text mt-1">To assign subscriptions to</span>
                                                        </div>
                                                        <div className="mb__20">
                                                            <label className='col-form-label'>Seller order reference number</label>
                                                            <input type="text" className="form-control" placeholder='' onChange={(e) =>
                                                                setFormData({ ...formData, sellerOrderRef: e.target.value })
                                                            }
                                                                value={formData.sellerOrderRef} />
                                                            {/* <span className="form-text mt-1"></span> */}
                                                        </div>
                                                    </>
                                                }
                                                <div className="mb__20">
                                                    <label className='col-form-label'>Customer</label>
                                                    <select className="form-control"
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                customerId: e.target.value === '---' ? '' : e.target.value 
                                                            })
                                                        }
                                                        value={formData.customerId}>
                                                        <option defaultValue>---</option>
                                                        {customers?.map((res, index) => (
                                                            <option value={res.customer_uuid} key={index}>{(res.customer_uuid) ? res.name + "(" + res.abn + ")"
                                                                : res.name + "(" + res.abn + ")"}</option>
                                                        ))}
                                                    </select>
                                                    {/* <span className="form-text mt-1"></span> */}
                                                </div>

                                                <div className="mb__20">
                                                    <label className='col-form-label'>Customer order reference number</label>
                                                    <input type="text" className="form-control" placeholder='' onChange={(e) =>
                                                        setFormData({ ...formData, customerOrderRef: e.target.value })
                                                    }
                                                        value={formData.customerOrderRef} />
                                                    {/* <span className="form-text mt-1"></span> */}
                                                </div>



                                                <div className="button-wrap d-flex flex-wrap align-items-center justify-content-between">
                                                    <button className='btn btn-primary btn-rounded me-3' onClick={assignSubs} disabled={disabled}>Assign Subscriptions</button>
                                                    <div className="alert alert-primary" role="alert">
                                                        Note: Subscriptions become Billable when Assigned to Customer
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className='user-permissions usr-prm devices-storage-wrapper'>
                                    <div className="page-heading">
                                        <h3>Subscriptions Not Ended Summary</h3>
                                    </div>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        {data?.map((rows, i) => (
                                                            <tr key={i}>
                                                                {i == 0 && rows?.map((row, index) => (
                                                                    <th key={index}>{row}  </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody>
                                                        {data?.map((rows, i) => (
                                                            <tr key={i}>
                                                                {i > 0 && rows?.map((row, index) => (
                                                                    <td key={index}>{row}  </td>

                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

