import React, { useState, useEffect } from 'react'
import { formValidation } from '../../helper/common';
import HeaderDashboard from '../common/HeaderDashboard'

export default function ReportProblem() {
    const [isDisabled, setIsDisabled] = useState(false)
    const [formData, setFormData] = useState({
        message: ''
    })

    useEffect(() => {
        formValidation(formData, setIsDisabled);
    }, [formData])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    return (
        <div className='d-flex flex-column site-main-wrapper content-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">


                <div className="main-page-height content-page d-flex flex-column">
                    <div className="page-title text-center">
                        <div className="container">
                            <h1>What’s happening?</h1>
                        </div>
                    </div>
                    <div className="flex-grow-1 content-page-wrapper">
                        <div className="container">
                            <div className="section-report">
                                <div className="section-title">
                                    <h2>Check all that apply.</h2>
                                </div>
                                <div className="report-checklist">
                                    <div className="mb-4 form-check">
                                        <input type="checkbox" className="form-check-input" id="report1" name='report1' />
                                        <label className="form-check-label" htmlFor="report1">
                                            <h6>Video problem</h6>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suspendisse et ut felis amet parturient non, adipiscing. Nulla diam, nunc scelerisque ac mattis ultricies malesuada sit ipsum.</p>
                                        </label>
                                    </div>
                                    <div className="mb-4 form-check">
                                        <input type="checkbox" className="form-check-input" id="report2" name='report2' />
                                        <label className="form-check-label" htmlFor="report2">
                                            <h6>Connection problem</h6>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suspendisse et ut felis amet parturient non, adipiscing. Nulla diam, nunc scelerisque ac mattis ultricies malesuada sit ipsum.</p>
                                        </label>
                                    </div>
                                    <div className="mb-4 form-check">
                                        <input type="checkbox" className="form-check-input" id="report3" name='report3' />
                                        <label className="form-check-label" htmlFor="report3">
                                            <h6>Sync problem</h6>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suspendisse et ut felis amet parturient non, adipiscing. Nulla diam, nunc scelerisque ac mattis ultricies malesuada sit ipsum.</p>
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="section-form">
                                <div className="section-title mb-3">
                                    <h2>Its something else</h2>
                                </div>
                                <div className="form-wrapper something-else-wrap">
                                    <form>
                                        <div className="mb-3">
                                            <textarea className="form-control" placeholder="Let us know whats going on." id='message' name='message' onChange={handleChange} rows={4} defaultValue={formData.message} ></textarea>
                                        </div>
                                        <div className="button-wrap">
                                            <button className="btn btn-primary btn-rounded" disabled={isDisabled}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}
