import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUiContext } from "../../../contexts/UiContext";
import { formValidation } from "../../../helper/common";
import HeaderDashboard from "../../common/HeaderDashboard";
import Leaflet from "../../common/Leaflet";
import Svg from "../../common/Svg";
import { toast } from "react-hot-toast";

export default function AddNewDevice() {
  let navigate = useNavigate();
  const { apiRequest } = useUiContext();
  let uid = localStorage.getItem("customeruuid");
  const [isDisabled, setIsDisabled] = useState(true);
  const [groups, setGroups] = useState([]);
  const [createGroup, setCreateGroup] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    oak: "",
    mac: "",
    // lat: "",
    // lon: "",
  });
  const [formData, setFormData] = useState({
    customer_uuid: uid,
    is_active: true,
    // lat: "",
    // lon: "",
    mac: "",
    name: "",
    oak: "",
    devicePassword: "",
    // cameraLocation: "",
    deviceUsername: "",
    group: "",
    sync_device_recordings: false,
    address_uuid: "",
    sitemap_uuid: "",
    device_type: "",
    sitemap_bearing: 0,
    sitemap_x_fraction: 0.3,
    sitemap_y_fraction: 0.3,
  });
  const [addresses, setAddresses] = useState([]);
  const [isAddressselected, setIsAddressSelected] = useState(false);
  const [selectAddressText, setSelectAddressText] = useState("");
  const [siteMapList, setSiteMapList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState(null);
  const [oakIsInalidMessage, setIsOakInalidMessage] = useState(false);
  const oakRegexPattern = /^[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}$/;

  useEffect(() => {
    const {
      // lat,
      // lon,
      mac,
      name,
      oak,
      password,
      // cameraLocation,
      username,
      address_uuid,
      sitemap_uuid,
    } = formData;
    formValidation(
      {
        // lat: lat,
        // lon: lon,
        mac: mac,
        name: name,
        oak: oak,
        password: password,
        // cameraLocation: cameraLocation,
        username: username,
        // address_uuid: address_uuid,
        // sitemap_uuid: sitemap_uuid,
      },
      setIsDisabled
    );
  }, [formData]);

  const cameraLocationHandler = () => {
    // if (formData.lat === "" && formData.lon === "")
      navigator.geolocation.getCurrentPosition(function (position) {
        setFormData({
          ...formData,
          // lat: position.coords.latitude,
          // lon: position.coords.longitude,
        });
      });
  };

  const getGroups = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/groups/", "", headers, false)
      .then((res) => {
        if (res.data?.results !== null) {
          setGroups(res.data.results);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAddressList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest("get", "api/1/addresses/", "", headers, true);
    if (res) {
      setAddresses(res.data.results);
    }
  };

  const getSitesList = async (address_uuid) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest(
      "get",
      `api/1/sitemaps/?address_uuid=${address_uuid}`,
      "",
      headers
    )
      .then((res) => {
        let response = res.data.results;
        setSiteMapList(response);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const getInputs = () => {
    apiRequest("get", "api_auth/1/form_choices").then((res) => {
      setDeviceTypeList(res.data.device_types);
    });
  };

  useEffect(() => {
    getGroups();
    getAddressList();
    getInputs();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const oakInputhandleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "oak" && oakRegexPattern.test(e.target.value)) {
      setIsOakInalidMessage(false);
      handleChange(e);
    } else {
      setIsOakInalidMessage(true);
    }
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, group: e.target.value });
  };
  const addNewCamera = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    if (formData.sitemap_uuid === "") {
       formData.sitemap_uuid=null;
    }

    if (formData.address_uuid === "") {
      formData.address_uuid=null;
   }
    if (formData.device_type === "") {
      delete formData.device_type;
    }
    let newFormData = formData;
    newFormData.username = formData.deviceUsername;
    newFormData.password = formData.devicePassword;
    return await apiRequest(
      "post",
      "api/1/devices/",
      newFormData,
      headers,
      true,
      { success: "Device added successfully", error: "Failed to add device" }
    )
      .then((res) => {
        if (res?.response?.data) {
          const errorsData = res.response.data;
          let newErrorState = {
            name: "",
            mac: "",
            // lat: "",
            // lon: "",
            oak: "",
          };
          if (errorsData.non_field_errors) {
            toast.error(errorsData.non_field_errors[0], { duration: 10000 });
          }
          if (errorsData.name) newErrorState.name = errorsData.name[0];
          // if (errorsData.lon) newErrorState.lon = errorsData.lon[0];
          // if (errorsData.lat) newErrorState.lat = errorsData.lat[0];
          if (errorsData.mac) newErrorState.mac = errorsData.mac[0];
          if (errorsData.oak) newErrorState.oak = errorsData.oak[0];
          if (errorsData.code && errorsData.code.length > 0)
            navigate("/customer/cameras");
          setErrors(newErrorState);
          // delete newFormData.deviceUsername;
          // delete newFormData.devicePassword;
        } else {
          console.warn(res.data);
          navigate("/customer/cameras");
          // delete newFormData.deviceUsername;
          // delete newFormData.devicePassword;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create group API
  const createHandler = (e) => {
    e.preventDefault();
    newGroup(createGroup);
  };

  const newGroup = async (createGroup) => {
    let header = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    let uid = localStorage.getItem("customeruuid");
    await apiRequest(
      "post",
      "api/1/groups/",
      { customer_uuid: uid, name: createGroup },
      header,
      true,
      { success: "Device Added Successfully", error: "Device not Added" }
    ).then(() => {
      getGroups();
    });
  };

  const pinDrag = async (latLng) => {
    // setFormData({...formData, lat: latLng.lat, lon: latLng.lng});
  }
  const cameraLink = () => {
    return (
      formData.mac !== "" && (
        <a
          className="btn btn-primary btn-rounded"
          href={`http://axis-${formData.mac}.local/`}
          target="_blank"
          rel="noreferrer"
        >
          Local Camera Config
        </a>
      )
    );
  };
  const isAdmin = localStorage.getItem("isadmin") === "true";

  const latitudeInputRef = useRef(null);
  const longitudeInputRef = useRef(null);

  function handlePaste(event) {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    if (isNaN(pastedData)) {
      event.preventDefault();
    }
  }

  return (
    <div className="d-flex flex-column site-main-wrapper">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="add-new-device-page">
          <div className="row add-dvc-row gx-4">
            <div className="col-lg-7">
              <div className="theme-form-wrapper">
                <div className="d-flex mb__20">
                  <label className="col-form-label text-end"></label>
                  <div className="flex-grow-1">
                    <div className="text-end new-tab-text">{cameraLink()}</div>
                  </div>
                </div>
                <div className="d-flex mb__20">
                  <label
                    htmlFor="deviceName"
                    className="col-form-label text-end"
                  >
                    Name
                  </label>
                  <div className="flex-grow-1 position-relative">
                    <input
                      type="text"
                      onChange={handleChange}
                      value={formData.name}
                      className="form-control"
                      id="deviceName"
                      name="name"
                      placeholder="Device Name"
                    />
                    {errors.name !== "" && (
                      <div
                        className="validation-msg"
                        onClick={() => setErrors({ ...errors, name: "" })}
                      >
                        {errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceSerialNumber"
                        className="col-form-label text-end"
                      >
                        Serial Number
                      </label>
                      <div className="flex-grow-1 position-relative">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.mac}
                          className="form-control"
                          id="deviceSerialNumber"
                          name="mac"
                          placeholder="Serial Number"
                        />
                        <div className="form-text">MAC Address</div>
                        {errors.mac !== "" && (
                          <div
                            className="validation-msg"
                            onClick={() => setErrors({ ...errors, mac: "" })}
                          >
                            {errors.mac}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceOAK"
                        className="col-form-label text-end"
                      >
                        OAK
                      </label>
                      <div className="flex-grow-1 position-relative">
                        <input
                          type="text"
                          onChange={oakInputhandleChange}
                          value={formData.oak}
                          className="form-control"
                          id="deviceOAK"
                          placeholder="OAK"
                          name="oak"
                        />
                        <div className="form-text">
                          Owner Authentication Key - example of OAK Key Format
                          XXXX-XXXX-XXXX
                        </div>
                        {oakIsInalidMessage && (
                          <div className="validation-msg">
                            Please add valid oak
                          </div>
                        )}
                        {errors.oak !== "" && (
                          <div
                            className="validation-msg"
                            onClick={() => setErrors({ ...errors, oak: "" })}
                          >
                            {errors.oak}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                
                  {/* <iframe src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13719.048058977454!2d76.72209695!3d30.725090149999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee706af99377%3A0x2c83adacca9d0913!2sFranco%20Hotel!5e0!3m2!1sen!2sin!4v1667193384767!5m2!1sen!2sin&q=${formData.latitude},${formData.longitude}`} width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
                  {/* <Leaflet
                    lat={formData.lat}
                    lng={formData.lon}
                    setFormData={setFormData}
                    formData={formData}
                    pinDrag={pinDrag}
                  /> */}
              
                <div className="d-flex mb__20">
                  {/* <label
                    htmlFor="cameraLocation"
                    className="col-form-label text-end"
                  >
                    Camera Location
                  </label> */}
                  <div className="flex-grow-1">
                    {/* <input
                      type="text"
                      onChange={handleChange}
                      value={formData.cameraLocation}
                      className="form-control"
                      id="cameraLocation"
                      name="cameraLocation"
                      placeholder="Camera Location"
                    /> */}
                    {/* <a
                      className="get-location form-text text-primary"
                      onClick={() => cameraLocationHandler()}
                    >
                      Use Browser Location
                    </a> */}
                  </div>
                </div>
                {/* <div className="d-flex align-items-center mb__20">
                  <label
                    htmlFor="cameraLocation"
                    className="col-form-label text-end"
                  >
                    Multi-Sensor Camera
                  </label>

                  <div className="flex-grow-1">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="multiSensor"
                        name="multiSensor"
                      />
                      <label
                        className="form-check-label green-border"
                        htmlFor="multiSensor"
                      >
                        Requires Applicable Subscription
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="Latitude"
                        className="col-form-label text-end"
                      >
                        Latitude
                      </label>
                      <div className="flex-grow-1 position-relative">
                        <input
                          pattern="[0-9]*"
                          type="number"
                          ref={latitudeInputRef}
                          onPaste={handlePaste}
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          onChange={handleChange}
                          value={formData.lat}
                          className="form-control latitude-input"
                          id="Latitude"
                          placeholder="Latitude"
                          name="lat"
                        />
                        {errors.lat !== "" && (
                          <div
                            className="validation-msg"
                            onClick={() => setErrors({ ...errors, lat: "" })}
                          >
                            {errors.lat}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="Longitude"
                        className="col-form-label text-end"
                      >
                        Longitude
                      </label>
                      <div className="flex-grow-1 position-relative">
                        <input
                          pattern="[0-9]*"
                          type="number"
                          ref={longitudeInputRef}
                          onPaste={handlePaste}
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          onChange={handleChange}
                          value={formData.lon}
                          className="form-control latitude-input"
                          id="Longitude"
                          placeholder="Longitude"
                          name="lon"
                        />
                        {errors.lon !== "" && (
                          <div
                            className="validation-msg"
                            onClick={() => setErrors({ ...errors, lon: "" })}
                          >
                            {errors.lon}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
                <hr />
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceUsername"
                        className="col-form-label text-end"
                      >
                        Username
                      </label>
                      <div className="flex-grow-1">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.deviceUsername}
                          className="form-control"
                          id="deviceUsername"
                          placeholder="Username"
                          name="deviceUsername"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="devicePassword"
                        className="col-form-label text-end"
                        autocomplete="off"
                      >
                        Password
                      </label>
                      <div className="flex-grow-1">
                        <input
                          type={
                            formData.devicePassword === "" ? "text" : "password"
                          }
                          onChange={handleChange}
                          value={formData.devicePassword}
                          className="form-control"
                          id="devicePassword"
                          placeholder="Password"
                          name="devicePassword"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="address_uuid"
                        className="col-form-label text-end"
                      >
                        Site
                      </label>
                      <div className="flex-grow-1">
                        <select
                          className="form-control"
                          name="address_uuid"
                          onChange={(e) => {
                            setIsAddressSelected(true);
                            setSelectAddressText("");
                            getSitesList(e.target.value);
                            handleChange(e);
                          }}
                        >
                          <option value="">Select site</option>
                          {addresses.map((site) => (
                            <option
                              value={site.address_uuid}
                              key={site.sitemap_uuid}
                            >
                              {site.name}
                            </option>
                          ))}
                        </select>
                        {selectAddressText.length > 0 && (
                          <p>{selectAddressText}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="sitemap"
                        className="col-form-label text-end"
                      >
                        Sitemap
                      </label>
                      <div className="flex-grow-1">
                        <select
                          className="form-control"
                          name="sitemap_uuid"
                          onClick={() => {
                            if (!isAddressselected) {
                              setSelectAddressText("Please Select Site");
                            }
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select sitemap</option>
                          {siteMapList.map((siteMap) => (
                            <option
                              value={siteMap.sitemap_uuid}
                              key={siteMap.sitemap_uuid}
                            >
                              {siteMap.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceType"
                        className="col-form-label text-end"
                      >
                        Device Type
                      </label>
                      <div className="flex-grow-1">
                        <select
                          className="form-control"
                          name="device_type"
                          onChange={handleChange}
                          value={formData.device_type}
                        >
                          <option value="">Select device type</option>
                          {deviceTypeList &&
                            Object.entries(deviceTypeList).map((device, i) => {
                              return (
                                <option value={device[0]} key={i}>
                                  {device[1]}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sync-device-checkbox">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="syncRecording"
                      name="sync_device_recordings"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sync_device_recordings: e.target.checked,
                        })
                      }
                      checked={formData.sync_device_recordings}
                    />
                    <label className="form-check-label" htmlFor="syncRecording">
                      <p>Sync device recordings</p>
                      <span>Copy to Cloud if Storage Subscribed</span>
                    </label>
                  </div>
                </div>
                <hr />
                <div className="d-flex mb__20 align-items-center">
                  <label className="col-form-label text-end">Is Active ?</label>
                  <div className="flex-grow-1">
                    <label className="switch" id="isActive">
                      <input
                        type="checkbox"
                        id="isActive"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            is_active: e.target.checked,
                          })
                        }
                        checked={formData.is_active}
                        defaultChecked
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <hr />
                <fieldset className="d-flex mb__20 group-checkbox align-items-center">
                  <legend className="col-form-label text-end mt-0">
                    Groups
                  </legend>
                  <div className="flex-grow-1">
                    <div className="d-flex flex-wrap group-checkbox-wrap align-items-center">
                      {groups?.map((group, index) => (
                        <div className="form-check" key={index}>
                          <input
                            type="radio"
                            name="group"
                            id={group.group_uuid}
                            value={group.group_uuid}
                            onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={group.group_uuid}
                          >
                            {group.name}
                          </label>
                        </div>
                      ))}

                      {/* <div className="form-check">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Your Group Name" />
                                                    <span className="input-group-text">
                                                        <button className='btn'>
                                                            <Svg name="check" />
                                                        </button>
                                                    </span>
                                                </div>
                                            </div> */}
                      <div className="form-check">
                        <button
                          className="add-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#addModal"
                        >
                          <Svg name="add-group" />
                        </button>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <hr />
                <div className="d-flex pt-3">
                  <legend className="col-form-label text-end d-none d-lg-block"></legend>
                  <div className="flex-grow-1">
                    <button
                      className="btn btn-primary btn-rounded"
                      onClick={addNewCamera}
                      disabled={isDisabled}
                    >
                      Add new device
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13719.048058977454!2d76.72209695!3d30.725090149999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee706af99377%3A0x2c83adacca9d0913!2sFranco%20Hotel!5e0!3m2!1sen!2sin!4v1667193384767!5m2!1sen!2sin" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}

                {/* <Leaflet
                  lat={formData.lat}
                  lng={formData.lon}
                  setFormData={setFormData}
                  formData={formData}
                  pinDrag={pinDrag}
                /> */}
              

            <div
              className="modal fade add-modal"
              id="addModal"
              tabIndex={-1}
              aria-labelledby="addModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {isAdmin ? (
                    <div className="modal-header">
                      <h5 className="modal-title text-center">
                        Create new group
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <Svg name="close-rounded" />
                      </button>
                    </div>
                  ) : null}
                  <div className="modal-body">
                    <div className="theme-form-wrapper">
                      <form>
                        <div className="d-flex mb__20 align-items-center">
                          <label
                            htmlFor="groupName"
                            className="col-form-label text-end"
                          >
                            Group name
                          </label>
                          <div className="flex-grow-1">
                            <input
                              type="text"
                              className="form-control"
                              id="groupName"
                              onChange={(e) => setCreateGroup(e.target.value)}
                              placeholder="Group name"
                            />
                          </div>
                        </div>
                        <div className="button-wrap text-center">
                          <button
                            className="btn btn-primary btn-rounded"
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={createHandler}
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
