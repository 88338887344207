import forge from "node-forge";
import ls from "localstorage-slim";
import {
  customerAdminPaths,
  distributorAdminPaths,
  customerPaths,
  sellerAdminPaths,
  sellerPaths,
  distributorPaths,
} from "../constants/allowedpath";

export const checkUserRoutePermission = (role, currentPath, allowedRoles = []) => {
  switch (role) {
    case "customerIsAdmin":
      return customerAdminPaths.indexOf(currentPath) !== -1;
    case "customer":
      let userRole = getLocal('userRole');
      return customerPaths.indexOf(currentPath) !== -1 && (allowedRoles.length > 0 ? allowedRoles.includes(userRole) : true);
    case "sellerIsAdmin":
      return sellerAdminPaths.indexOf(currentPath) !== -1;
    case "seller":
      return sellerPaths.indexOf(currentPath) !== -1;
    case "distributorIsAdmin":
      return distributorAdminPaths.indexOf(currentPath) !== -1;
    case "distributor":
      return distributorPaths.indexOf(currentPath) !== -1;
    default:
      return false;
  }
};

export const logoutUser = () => {
  localStorage.removeItem("x_verified_jwt");
  localStorage.removeItem("token");
  localStorage.removeItem("customeruuid");
  localStorage.removeItem("startDate");
  localStorage.removeItem("endDate");
  localStorage.removeItem("show");
  localStorage.removeItem("userRole")
  localStorage.clear();
  return true;
};

export const isUserLoggedIn = (ignoreVerified = false) => {
  return (
      (localStorage.getItem("token") !== null) &&
      (ignoreVerified ||
          (localStorage.getItem("x_verified_jwt") !== null)
      ));
};

export const setNavbarDropdown = (pathname, setActiveRouteName) => {
  const pathnameArray = pathname.split("/");
  if (pathnameArray.includes("cameras")) {
    setActiveRouteName("Camera");
  } else if (pathnameArray.includes("recordings")) {
    setActiveRouteName("Recordings");
  } else if (
    pathnameArray.includes("groups") ||
    pathnameArray.includes("group")
  ) {
    setActiveRouteName("Groups");
  } else {
    const role = getLocal("role");
    if (role === "distributor" || role === "distributorIsAdmin") {
      setActiveRouteName("Distributor Administration");
    } else if (role === "seller" || role === "sellerIsAdmin") {
      setActiveRouteName("Reseller Administration");
    } else {
      setActiveRouteName("Settings");
    }
  }
};

export const formValidation = (fields, setIsDisabled, setEmailError) => {
  // array for getting status of all fields
  let statusArray = [];

  for (const key in fields) {
    // if form has email field
    if (setEmailError && (key === "email" || key === "contact_email") && fields[key] !== "") {
      if (
        fields[key].match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        setEmailError(false);
        statusArray.push("false");
      } else {
        setEmailError(true);
        statusArray.push("true");
      }
    } else {
      // validation for fields other than email
      if (fields[key] === "" || /^\s*$/.test(fields[key]) ||  fields[key] === false) {
        statusArray.push("true");
      } else {
        statusArray.push("false");
      }
    }
  }

  // checking is there any field which is invalid/empty
  setIsDisabled(statusArray.includes("true"));
};

//Used for encrypting the string using RSA Encryption
export const encryptString = (password) => {
  const pubKey = localStorage.getItem("publicKey");
  if (pubKey !== "" && pubKey !== undefined && pubKey !== null) {
    // noinspection JSUnresolvedVariable
    const publicKey = forge.pki.publicKeyFromPem(pubKey);
    // noinspection JSUnresolvedVariable
    const encodedPassword = forge.util.encodeUtf8(password);
    // noinspection JSUnresolvedVariable
    const encData = publicKey.encrypt(encodedPassword, "RSA-OAEP", {
      md: forge.md.sha512.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    // noinspection JSUnresolvedVariable
    return forge.util.encode64(encData);
  }
};

export const setLocal = (key, value) => {
  ls.config.encrypt = true;
  return ls.set(key, value);
};

export const getLocal = (key) => {
  ls.config.encrypt = true;
  return ls.get(key);
};
