import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import HeaderAuthentication from '../common/HeaderAuthentication';
import {encryptString} from "../../helper/common";
import {formValidation} from "../../helper/common";
import {useUiContext} from '../../contexts/UiContext';

// import { ToastContainer, toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    let terms = localStorage.getItem("terms");
    let privacy = localStorage.getItem("privacy");

    // const error = () => toast.error("This is an error!");
    // const warning = () => toast.warn("This is a warning!");


    const {apiRequest} = useUiContext();
    let navigate = useNavigate();
    const [passwordType, setPasswordType] = useState('password');
    const [emailError, setEmailError] = useState(true);
    const [isDisabled, setisDisabled] = useState(true);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        enc_password: '',
        terms_checkbox: false
    });

    const viewPassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    };
    useEffect(() => {
        const {email, password, terms_checkbox} = formData;
        formValidation({email, password, terms_checkbox}, setisDisabled, setEmailError);
    }, [formData]);

    const fetchData = async () => {
        await apiRequest("get", "api_auth/1/login", "", {}, true).then(res => {
            if (res?.data) {
                localStorage.setItem("privacy", res.data?.privacy_policy_url);
                localStorage.setItem("publicKey", res.data?.public_rsa_key);
                localStorage.setItem("terms", res.data?.terms_and_conditions_url);
                localStorage.setItem("help", res.data?.help_url);
            }
        });
    };

    useEffect(() => {
        fetchData().then(_ => {
        });
    }, []);


    const Login = async () => {
        const headers = {
            'Content-Type': 'application/json',
        };
        let formDataValues = formData;
        formDataValues.enc_password = encryptString(formDataValues.password);
        const res = await apiRequest("post", "api_auth/1/login",
            {
                email: formDataValues.email,
                enc_password: formDataValues.enc_password,
                terms_checkbox: formDataValues.terms_checkbox
            }, headers, true, {error: 'Invalid email and password'});
        if (res) {
            localStorage.setItem("token", res.data.token);
            localStorage.removeItem("x_verified_jwt");
            navigate(`/verify-code`);
        }
    };

    /* jshint ignore:start */
    return (
        <>
            <div className='d-flex flex-column site-auth-wrapper'>
                <HeaderAuthentication/>
                <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
                    <div className="container">
                        <div className="form-wrapper">
                            <div className="form-heading text-center">
                                <h3>Welcome back!</h3>
                                <p>Use your work email to log in or get started</p>
                            </div>
                            <div className="field-wrapper">
                                <div
                                    className="mb__20 position-relative error"> {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                    <input type="email" className="form-control" value={formData.email} placeholder="Work Email"
                                           name="email" onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                                    {formData.email !== "" && emailError &&
                                        <div className="validation-msg">This is not a valid email</div>}
                                </div>
                                <div
                                    className="mb-2 position-relative">{/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                    <input
                                        type={passwordType}
                                        className="form-control"
                                        value={formData.password}
                                        placeholder="Password"
                                        name="password" onChange={(e) => setFormData({...formData, password: e.target.value})}/>
                                    {/* <div className="validation-msg">The password entered is not correct</div> */}
                                    <span className={"forgot-wrap " + passwordType} onClick={viewPassword}>
                                        <i className="fa fa-eye " aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div className={"additional-info mb__20 d-flex justify-content-between flex-wrap"}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="remember" name="terms_checkbox"
                                               checked={formData.terms_checkbox}
                                               onChange={(e) => setFormData({...formData, terms_checkbox: e.target.checked})}/>
                                        <label className="form-check-label" htmlFor="remember">
                                            I accept the <a href={terms} target='_blank' rel="noreferrer"> Terms and Conditions</a>
                                        </label>
                                    </div>
                                    <Link to="/verify-email" className='btn-link reset-password align-self-end'>Forgot
                                        password?</Link>
                                </div>
                            </div>
                            <div className="button-wrap">
                                <button className='btn btn-primary' disabled={isDisabled} onClick={Login}>Continue</button>
                            </div>
                            <div className="form-text text-center">
                                <p>Don’t have an account? <Link to="/sign-up">Create an account</Link></p>
                            </div>
                        </div>

                        <div className="outer-form-text">
                            <p>By continuing, you agree to <a href={terms} target='_blank' rel="noreferrer"> Terms of Use</a>
                                Read our <a href={privacy} target='_blank' rel="noreferrer">Privacy Policy</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {/* <button onClick={error}>Error !</button>
                <button onClick={warning}>Warning !</button> */}
                {/* <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                /> */}
                {/* Same as */}
                {/* <ToastContainer /> */}


            </div>
        </>
    )
    /* jshint ignore:end */
}
