import React, { useState, useEffect, forwardRef } from 'react';
import GroupSidebar from '../../common/GroupSidebar';
import HeaderDashboard from '../../common/HeaderDashboard';
import Svg from '../../common/Svg';
import { Link, useParams } from 'react-router-dom';
import { useUiContext } from '../../../contexts/UiContext';

import { ReactSortable } from "react-sortablejs";
import { getLocal, checkUserRoutePermission } from "../../../helper/common";



const CustomComponent = forwardRef < HTMLDivElement > ((props, ref) => {
    return <div ref={ref}>{props.children}</div>;
});


export default function Index({route}) {
    let role = getLocal("role")
    const [state, setState] = useState([
        {id: 1, name: "Group 1"},
        {id: 2, name: "Group 2"},
        {id: 3, name: "Group 3"},
        {id: 4, name: "Group 4"},
        {id: 5, name: "Group 5"},
        {id: 6, name: "Group 6"},
    ]);

    const [enable, setEnable] = useState(true)
    const [groupList, setGroupList] = useState({groups: [], checkedList: {}});
    const [createGroup, setCreateGroup] = useState("")
    const [groupUuid, setGroupUuid] = useState("");
    const [refreshData, setRefreshData] = useState(0);
    const [checkedDevice, setCheckedDevice] = useState([])

    const {apiRequest, refreshCount, setLoading, searchFilter} = useUiContext();


    const groupsList = async () => {
        let header = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        let params = {};
        if (groupUuid) params.group_uuid = groupUuid;
        if (searchFilter) params.name__contains = searchFilter;
        return await apiRequest("get", 'api/1/groups/', {}, header, true, {success: '', error: ''}, params).then(res => {
            let checkedList = {}
            res.data.results.map((el) => {
                checkedList[el.group_uuid] = false;
                return el
            })
            setGroupList({...groupList, groups: res.data.results, checkedList: checkedList})
        }).catch((err) => {
            console.error(err);
        });
    }
    // Get group List
    useEffect(() => {
        groupsList().then(_ => {});
    }, [groupUuid, searchFilter])

    // Create group API
    const createHandler = (e) => {
        e.preventDefault()
        newGroup(createGroup).then(_ => {
        });
    }

    const newGroup = async (createGroup) => {
        let header = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        let uis = localStorage.getItem("customeruuid")
        return await apiRequest("post", 'api/1/groups/', 
        {customer_uuid: uis, name: createGroup}, 
        header, 
        true,
        {success:'Group has been created', error: 'Failed to create group',showToast:true}).then(_ => groupsList());
    }


    // Delete geoup API 
    const deleteGroup = async (group_uuid) => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt")
        }

        return await apiRequest("delete", `api/1/groups/${group_uuid}/`, {}, headers, true,
            {success: 'Group has been deleted', error: 'Failed to delete  group'}).then(_ => groupsList());
    }

    const deleteHandler = () => {
        let deletes = [];
        for (let key in groupList.checkedList) {
            if (groupList.checkedList[key] === true) {
                deletes.push(deleteGroup(key));
            }
        }
        return Promise.all(deletes);
    }

    const [isDelete, setIsDelete] = useState(false);

    const checkedHandler = (id) => {
        let checkedList = groupList.checkedList;
        checkedList["" + id] = !checkedList["" + id];
        setGroupList({...groupList, checkedList: checkedList})
        const isCheckedArr = [];
        for (let i in groupList.checkedList) {
            if (groupList.checkedList[i]) {
                isCheckedArr.push(1);
            } else {
                isCheckedArr.push(0);
            }
        }
        setIsDelete(isCheckedArr.includes(1));
    }
    const addDevice = async (device_uuid, groupId) => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt")
        }
        return apiRequest("post", `api/1/groups/${groupId}/add_device_to/`, {device_uuid: device_uuid}, headers, true, {
            success: 'Camera has been added',
            error: 'Failed to add camera'
        });
        // getDevicesInGroup()
        // navigate('/customer/groups');
    }

    const handleAddDevice = async (groupId) => {
        let adds = [];
        for (let i = 0; i < checkedDevice.length; i++) {
            adds.push(addDevice(checkedDevice[i], groupId));
        }
        return Promise.all(adds);
    }

    const addDevicesToGroups = async () => {
        let adds = [];
        for (let groupId in groupList.checkedList) {
            if (groupList.checkedList[groupId]) {
                adds.push(handleAddDevice(groupId));
            }
        }
        return Promise.all(adds).then(_ => {
            groupsList();
            setRefreshData(Math.random())
        });
    }
    const isAdmin = localStorage.getItem("isadmin") === "true";

    return (
        <>

            <div className='d-flex flex-column site-main-wrapper grid-page'>
                <HeaderDashboard route={route}/>
                <div className="flex-grow-1 main-wrapper">
                    {/* listing page */}
                    <div className="main-page-height devices-page d-flex">
                        <GroupSidebar setEnable={setEnable} enable={enable} route={route} setCheckedDevice={setCheckedDevice}
                                      handleAddDevice={addDevicesToGroups} refreshData={refreshData}/>
                        <div className="flex-grow-1">
                            <div className="d-flex flex-column">
                                {isAdmin ? <div className="camera-header d-flex align-items-center details-header">
                                    <div className="flex-grow-1 available-heading ps-0">
                                        <h2>Create New Group
                                            <button className='add-btn' data-bs-toggle="modal" data-bs-target="#addModal"><Svg
                                                name="add-gropup-note"/></button>
                                        </h2>
                                    </div>
                                    {isDelete &&
                                        <div className="button-wrap">
                                            <button className='btn btn-delete ' data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                <Svg name="delete-icon"/>
                                            </button>

                                        </div>
                                    }
                                </div> : null}
                                <div className="flex-grow-1 camera-list-wrapper">
                                    <div className="groups-list-wrapper accordion" id="accordionGroups">
                                        {/* <div className="button-wrap mb-3 text-end">
                                            <button className='btn btn-primary btn-rounded' data-bs-toggle="modal" data-bs-target="#orderModal">Change Order</button>
                                        </div> */}
                                        <div className="row" onClick={() => setGroupUuid('')}>

                                            {groupList?.groups.map((data, Index) =>
                                                <div className="col-lg-4" key={data.group_uuid}>
                                                    {checkUserRoutePermission(role, `/${route}/group/details/:group_uuid`) &&
                                                        <div
                                                            className={`accordion-item list-item d-flex align-items-center ${(groupList.checkedList["" + data.group_uuid]) ? "selected" : ""}`}
                                                            key={Index}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       checked={(groupList.checkedList["" + data.group_uuid])}
                                                                       id={`group${Index}`}
                                                                       onChange={() => checkedHandler(data.group_uuid)}/>
                                                                <label className="form-check-label d-flex align-items-center"
                                                                       htmlFor={`group${Index}`}></label>
                                                            </div>
                                                            <Link className="accordion-button link-btn flex-grow-1"
                                                                  to={`/${route}/group/details/${data.group_uuid}`}>
                                                                {data.name}
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade add-modal" id="addModal" tabIndex={-1} aria-labelledby="addModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center">Create new group</h5>
                            <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                                <Svg name="close-rounded"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="theme-form-wrapper">
                                <form>
                                    <div className="d-flex mb__20 align-items-center">
                                        <label htmlFor="groupName" className="col-form-label text-end">Group name</label>
                                        <div className="flex-grow-1">
                                            <input type="text" className="form-control" id="groupName"
                                                   onChange={(e) => setCreateGroup(e.target.value)} placeholder='Group name'/>
                                        </div>
                                    </div>
                                    <div className="button-wrap text-center">
                                        <button className='btn btn-primary btn-rounded' type='button' data-bs-dismiss="modal"
                                                onClick={createHandler}>Create
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="modal fade add-modal" id="orderModal" tabIndex={-1} aria-labelledby="addModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center">Change Order</h5>
                            <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                                <Svg name="close-rounded"/>
                            </button>
                        </div>
                        <div className="modal-body sortable-group">
                            <ReactSortable animation={200} delayOnTouchStart={true} delay={2} tag={CustomComponent} list={state}
                                           setList={setState}>
                                {state.map((item) => (
                                    <div className='text-wrap d-flex align-items-center' key={item.id}>
                                        <div className="icon-wrap">
                                            <Svg name="draggable"/>
                                        </div>
                                        <div className="flex-grow-1 text-inner">
                                            <div className="sec-name">{item.name}</div>
                                        </div>
                                    </div>

                                ))}
                            </ReactSortable>
                            <div className="button-wrap text-center pt-3">
                                <button className='btn btn-primary btn-rounded' type='button' data-bs-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            <div className="modal fade modal-delete" id="deleteModal" tabIndex={-1} aria-labelledby="deleteModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-wrap">
                                <h4>Are you sure you want to delete this group? </h4>
                                <div className="note">
                                    <div className="d-flex heading">
                                        <Svg name="delete-modal-icon"/>
                                        <div className="flex-grow-1 ps-3">Warning</div>
                                    </div>
                                    <p>NOTE - Devices in this group will not be deleted</p>
                                </div>
                            </div>
                            <div className="button-wrap d-flex justify-content-end">
                                <button type="button" className="btn btn-delete-new" data-bs-dismiss="modal"
                                        onClick={deleteHandler}>Delete
                                </button>
                                <button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
