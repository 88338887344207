import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDashboard from '../common/HeaderDashboard'
import ResellerHeader from '../common/ResellerHeader'
import ResellerHeadingInner from '../common/ResellerHeadingInner'

export default function BillableSubscriptionsSummary() {
    return (
        <>

            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <ResellerHeader />
                        <div className="flex-grow-1 setting-page-wrapper">

                            <div className="container">

                                <div className='user-permissions usr-prm devices-storage-wrapper'>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Distributor </th>
                                                            <th scope="col">Customer</th>
                                                            <th scope="col">Product Code</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col" className="text-center">Subscriptions</th>
                                                            <th scope="col" className="text-center">Allow Devices</th>
                                                            <th scope="col" className="text-center">Storage Quota</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>test distributor delta </td>
                                                            <td>test customer one</td>
                                                            <td>test-device-A-monthly</td>
                                                            <td>TEST Device Plan A monthly</td>
                                                            <td className="text-center">48</td>
                                                            <td className="text-center">48</td>
                                                            <td className="text-center">-</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test distributor delta </td>
                                                            <td>test customer one</td>
                                                            <td>test-device-A-monthly</td>
                                                            <td>TEST Device Plan A monthly</td>
                                                            <td className="text-center">4</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">200.0 GB</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Total </td>
                                                            <td>Total </td>
                                                            <td>Total </td>
                                                            <td>Total </td>
                                                            <td className="text-center">52</td>
                                                            <td className="text-center">48</td>
                                                            <td className="text-center">200.0 GB</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="button-wrap pt-3 text-center">
                                                {/* <button className='btn btn-delete btn-rounded btn-300' data-bs-toggle="modal" data-bs-target="#endSubscription">End subscription </button> */}

                                                <Link to="/reseller/end-subscriptions" className='btn btn-delete btn-rounded btn-300'>End subscription </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade modal-delete" id="endSubscription" tabIndex={-1} aria-labelledby="endSubscriptionLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-wrap">
                                <h4>Are you sure you want to delete this subscription? </h4>
                                <div className="note">
                                    NOTE - Devices in this subscription will not be deleted
                                </div>
                            </div>
                            <div className="button-wrap d-flex justify-content-end">
                                <button type="button" className="btn btn-delete-new">Delete</button>
                                <button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
