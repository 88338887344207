import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUiContext } from '../../../contexts/UiContext'
import HeaderDashboard from '../../common/HeaderDashboard'
import ResellerHeader from '../../common/ResellerHeader'
import SettingNavigation from '../../common/SettingNavigation'
import {getLocal,checkUserRoutePermission} from "../../../helper/common"

export default function UnassignedSubscriptionsSummary({route}) {
    let role = getLocal("role")
    let uid = localStorage.getItem("customeruuid")
    const { apiRequest } = useUiContext();
    const [data, setData] = useState([]);
    const endpoint = (route === 'customer')?'customers':(route === 'reseller')?'sellers':'distributors'
    const getData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }

        const res = await apiRequest('get', `api/1/${endpoint}/${uid}/get_summary_subscriptions_not_activated/`, "", headers, true);
        if (res) {
            setData(res.data.rows)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;
    return (
        <>

            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                     <SettingNavigation />
                        <div className="flex-grow-1 setting-page-wrapper">
                            <div className="container">
                                <div className='user-permissions usr-prm devices-storage-wrapper'>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">
                                        <div className="table-responsive">
                                                <table className="table">
                                                <thead>
                                                    {data?.map((rows,i)=>(
                                                        <tr key={i}>
                                                            {i==0&&rows.map((row,index)=>(
                                                            <th key={index}>{row}  </th>
                                                            ))}
                                                        </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody>
                                                    {data?.map((rows,i)=>(
                                                        <tr key={i}>
                                                            {i>0&&rows.map((row,index)=>(
                                                            <td key={index}>{row}  </td>
                                                           
                                                            ))}
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                             <div className="button-wrap pt-3 text-center">
                                             {isAdmin ?checkUserRoutePermission(role,`/${route}/unassigned-subscriptions-summary/assign`) && <Link to={`/${route}/unassigned-subscriptions-summary/assign`} className='btn btn-secondary btn-rounded btn-300 me-3 my-2'>Assign Subscriptions</Link> :null}
                                             {isAdmin ? checkUserRoutePermission(role,`/${route}/unassigned-subscriptions-summary/create`) &&  <Link to={`/${route}/unassigned-subscriptions-summary/create`} className='btn btn-secondary btn-rounded btn-300 my-2'>Create Subscription</Link> :null}                  
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
