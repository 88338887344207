import React,{useEffect} from 'react';

import { useNavigate } from "react-router-dom";

import { isUserLoggedIn } from "../helper/common";

function Redirect(props) {
    const {Component} =  props;
    const navigate = useNavigate();
  useEffect(() => {
    if (isUserLoggedIn()) {
      navigate('/customer/cameras');
    }
  });
  /* jshint ignore:start */
  return (
    <>
    <Component/>
  </>
  )
  /* jshint ignore:end */
}

export default Redirect;
