import React,{useEffect,useRef,useState} from 'react'
import { useUiContext } from '../../contexts/UiContext';
import HeaderDashboard from '../common/HeaderDashboard'

export default function PrivacyPolicy() {
    const { apiRequest } = useUiContext();
    const privacyDivTag = useRef();
    const [innerHtml, setInnerHtml] = useState("");


    useEffect(() => {
        privacyDivTag.current.innerHTML = innerHtml;
    }, [innerHtml])

    const setPrivacyData = async() =>{
        const headers = {
            'Content-Type': 'application/json',
        }
        const res = await apiRequest("get", "api_auth/1/login", "", headers,true);
        if (res){
            setInnerHtml(res.data.privacy_html);
        }
    }
    useEffect(()=>{
        setPrivacyData();
        
    },[])

    return (
        <div className='d-flex flex-column site-main-wrapper content-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                

                <div className="main-page-height content-page d-flex flex-column">
                    <div className="page-title text-center">
                        <div className="container">
                            <h1>Privacy Policy</h1>
                        </div>
                    </div>
                    <div className="flex-grow-1 content-page-wrapper">
                        <div className="container">
                            <div className="section-text" ref={privacyDivTag}> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
