import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useUiContext } from "../../contexts/UiContext";
import Svg from "./Svg";
import { getLocal, checkUserRoutePermission } from "../../helper/common";
export default function RecordingDetailsSidebar({
  route,
  clearSelectedSiteData,
  setCameraEventsOptions,
  setSelectedEvents
}) {
  let role = getLocal("role");
  const { apiRequest, setSearchFilter } = useUiContext();
  const { device_uuid } = useParams();
  const [sortBy, setSortBy] = useState("");
  const [page, setpage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  let navigate = useNavigate();

  const { pathname } = useLocation();
  const [deviceListData, setDeviceListData] = useState(null);

  const getDeviceList = async (currentpage = page) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    // const res = await apiRequest("get", `api/1/devices/?ordering=${sortBy}`, "", headers, false)
    const res = await apiRequest(
      "get",
      `api/1/devices/?ordering=${sortBy}&page=${currentpage}`,
      "",
      headers,
      false
    );
    let data = res.data.results;
    if (deviceListData == null) {
      setDeviceListData(data);
    } else {
      setDeviceListData((prevState) => {
        return [...prevState, ...data];
      });
    }
    setTotalPage(res.data.page_count);
  };

  useEffect(() => {
    getDeviceList();
  }, [sortBy]);

  const handleScroll = () => {
    if (page <= totalPage) {
      setpage((prePage) => prePage + 1);
      if (page !== 1) {
        getDeviceList(page);
      }
    }
  };

  return (
    <div className="sidebar-theme sidebar-details">
      <div className="camera-header d-flex align-items-center">
        <div className="flex-grow-1 available-heading">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-link p-0"
              onClick={() => {
                navigate(`/customer/cameras`);
                setSearchFilter("");
              }}
            >
              <Svg name="back-arrow-big" />
            </button>
            <div className="dropdown-wrap d-flex align-items-center">
              <label>Sort by -</label>
              <div className="dropdown">
                <button
                  className="btn p-0 dropdown-toggle"
                  type="button"
                  id="dropdownSorting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Name
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownSorting"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setSortBy("name")}
                    >
                      A To Z
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setSortBy("-name")}
                    >
                      Z to A
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-wrapper"
        id="devicelist-wrapper"
        onScroll={handleScroll}
        style={{ overflowY: "scroll", height: "100vh" }}
      >
        <ul className="camera-list">
          {deviceListData?.map((device) => (
            <li
              className={`d-flex ${
                device.device_uuid == device_uuid ? "active" : ""
              }`}
              key={device.device_uuid}
              onClick={() => {
                if (setCameraEventsOptions) {
                  setCameraEventsOptions(["All Events"]);
                }
                if (clearSelectedSiteData) {
                  clearSelectedSiteData();
                }
                if (setSelectedEvents) {
                  setSelectedEvents([]);
                }
              }}
            >
              {pathname.includes("details") &&
              checkUserRoutePermission(
                role,
                `/${route}/cameras/details/:device_uuid`
              ) ? (
                <Link to={`/${route}/cameras/details/${device.device_uuid}`}>
                  <div className="camera-name">{device.name}</div>
                </Link>
              ) : checkUserRoutePermission(
                  role,
                  `/${route}/cameras/live-camera/:device_uuid`
                ) ? (
                <Link
                  to={`/${route}/cameras/live-camera/${device.device_uuid}`}
                >
                  <div className="camera-name">{device.name}</div>
                </Link>
              ) : (
                <></>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
