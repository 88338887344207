import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUiContext } from '../../../contexts/UiContext';
import HeaderDashboard from '../../common/HeaderDashboard';
import SettingNavigation from '../../common/SettingNavigation';
import Svg from '../../common/Svg';

export default function EndSubscriptions({ route }) {
    let name = localStorage.getItem("username");
    let uid = localStorage.getItem("customeruuid");
    const { apiRequest } = useUiContext();
    const [uniqueProductCodes, setUniqueProductCodes] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPageNo, setTotalPageNo] = useState(1);
    const [sellers, setSellers] = useState([]);
    const [data, setData] = useState([]);
    const endpoint = (route === 'customer') ? 'customers' : (route === 'reseller') ? 'sellers' : 'distributors';
    const queryID = (route === 'customer') ? 'customer_uuid' : (route === 'reseller') ? 'seller_uuid' : 'distributor_uuid';
    const [subscriptionList, setSubscriptionList] = useState({ results: [], productCode: [], checkedKeys: [], subscriptionKey: [], filteredData: [], });

    useEffect(() => {
    }, [subscriptionList.ischecked]);


    useEffect(() => {
        getAllData(pageNo);
    }, [pageNo]);



    const getAllData = async (pageNo) => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        const res = await apiRequest('get', `api/1/subscriptions/?page${pageNo}`, "", headers, true);
        if (res) {
            setTotalPageNo(res.data.page_count);
            setSubscriptionList({ ...subscriptionList, results: res.data.results, });
        }

    };

    let newArray = [];
    subscriptionList.results.forEach((res) => {
        newArray.push(res.product);
    });
    useEffect(() => {
        let resultArray = newArray;
        const key = "product_code";
        let unique = [...new Map(resultArray.map(item => [item[key], item])).values()];
        setUniqueProductCodes(unique);
    }, [subscriptionList]);



    const formData = [];
    subscriptionList.checkedKeys.map(key => formData.push(
        {
            "subscription_key": key
        })
    );

    // console.log("subscription key", subscriptionList.productCode)

    const applyFilter = () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        subscriptionList.productCode.forEach(async code => {
            const res = await apiRequest('get', `api/1/subscriptions?${queryID}=${uid}&product_code=${code},product_code=${code}`, "", headers, true);
            if (res) {
                setSubscriptionList({ ...subscriptionList, filteredData: res.data.results, });

            }
        }
        );

    };
    const deleteSubscription = async (route) => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        if (route === "reseller" || route === "customer") {
            const res = await apiRequest('post', `api/1/${endpoint}/${uid}/end_subscriptions/?customer_assigned_timestamp__isnull=${false}&ended_by_timestamp__isnull=${true}`, { subscriptions: formData }, headers, true, { success: 'End Successfully', error: 'Subscription not ended' });
            if (res) {
                getAllData();
            }
        } else {
            const res = await apiRequest('post', `api/1/${endpoint}/${uid}/end_subscriptions/?customer_assigned_timestamp__isnull=${false}&ended_by_timestamp__isnull=${true}&seller_assigned_timestamp__isnull=${false}`, { subscriptions: formData }, headers, true, { success: 'End Successfully', error: 'Subscription not ended' });
            if (res) {
                getAllData();
            }
        }
    };

    const selectProduct = (e) => {
        const { value, checked } = e.target;
        let productCode = subscriptionList.productCode;
        const indexOfCode = productCode.indexOf(value);
        if (indexOfCode === -1) {
            productCode.push(value);
            setSubscriptionList({ ...subscriptionList, ischecked: checked });
        } else {
            productCode.splice(indexOfCode, 1);
            setSubscriptionList({ ...subscriptionList, ischecked: checked });
        }
        setSubscriptionList({ ...subscriptionList, productCode: productCode });
    };



    const handleSeller = (id) => {
        let sellerId = subscriptionList.subscriptionKey;
        const indexOfKey = sellerId.indexOf(id);
        if (indexOfKey === -1) {
            sellerId.push(id);
        } else {
            sellerId.splice(indexOfKey, 1);
        }
        setSubscriptionList({ ...subscriptionList, subscriptionKey: sellerId });
    };


    const checkedHandler = (id) => {
        // let checkedList = subscriptionList.checkedList;
        // checkedList["" + id] = !checkedList["" + id];
        let checkedKeys = subscriptionList.checkedKeys;
        const indexOfKey = checkedKeys.indexOf(id);
        if (indexOfKey === -1) {
            checkedKeys.push(id);
        } else {
            checkedKeys.splice(indexOfKey, 1);
        }
        setSubscriptionList({ ...subscriptionList, checkedKeys: checkedKeys });

    };

    /* jshint ignore:start */
    return (
        <>
            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <SettingNavigation />
                        <div className="flex-grow-1 setting-page-wrapper">
                            <div className="container">
                                <div className="user-permissions">
                                    <Link to={`/${route}/billable-subscriptions`} className="back-btn d-inline-flex align-items-center">
                                        <Svg name="left-arrow" /> Back
                                    </Link>
                                    <div className="create-user">
                                        <div className="page-heading">
                                            <h3>End Subscriptions - Customer: <small>{name}</small></h3>
                                        </div>
                                        <div className="create-user-wrapper">
                                            <div className="form-wrapper theme-form-wrapper">

                                                {/* <div className="filter-wrapper">
                                                    <div className="fil-in">
                                                        <div className="filter-inner">
                                                            <h4>Products</h4>
                                                            <div className="cameras-list">
                                                                <div className="camera-wrap">
                                                                    {uniqueProductCodes.map((res, index) => (
                                                                        <div className="form-check" key={index}>
                                                                            <input className="form-check-input"
                                                                                checked={subscriptionList?.ischecked}
                                                                                type="checkbox" id={`filterProduct${index}`}
                                                                                value={res.product_code} onChange={(e) => selectProduct(e)} />
                                                                            <label className="form-check-label d-flex align-items-center" htmlFor={`filterProduct${index}`}>
                                                                                {res.product_code + '(' + res.name + ')'}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="filter-inner">
                                                            <h4>Sellers</h4>
                                                            <div className="cameras-list">
                                                                <div className="camera-wrap">
                                                                    {subscriptionList.results.map((res, index) => (
                                                                        <div className="form-check" key={index}>
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id={`filterSellers${index}`} onChange={() => handleSeller(res.seller_uuid
                                                                                )} />
                                                                            <label className="form-check-label d-flex align-items-center" htmlFor="filterSellers1">
                                                                                {res.seller_uuid}

                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="button-wrap">
                                                        <button className='btn btn-primary btn-rounded' onClick={applyFilter}>Apply Filters</button>
                                                    </div>
                                                </div> */}


                                                <div className="groups-list-wrapper">
                                                    {subscriptionList?.filteredData.length === 0 ? subscriptionList?.results.map((res, index) => (
                                                        res.end_timestamp === null &&

                                                        <div className="accordion-item list-item d-flex align-items-center " key={index}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id={`subscription${index}`} onChange={() => checkedHandler(res.subscription_key
                                                                )} />
                                                                <label className="form-check-label d-flex align-items-center" htmlFor={`subscription${index}`} >
                                                                </label>
                                                            </div>
                                                            <p className="accordion-button link-btn flex-grow-1">{res.subscription_key + " " + res.customer_assigned_timestamp + " " + res.product.name

                                                            }
                                                            </p>
                                                        </div>
                                                    )) : subscriptionList?.filteredData.map((res, index) => (
                                                        res.end_timestamp === null &&

                                                        <div className="accordion-item list-item d-flex align-items-center " key={index}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id={`subscription${index}`} onChange={() => checkedHandler(res.subscription_key
                                                                )} />
                                                                <label className="form-check-label d-flex align-items-center" htmlFor={`subscription${index}`} >
                                                                </label>
                                                            </div>
                                                            <p className="accordion-button link-btn flex-grow-1">{res.subscription_key + " " + res.customer_assigned_timestamp + " " + res.product.name

                                                            }
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>


                                                <div className='d-md-flex flex-wrap justify-content-center page-nav'>
                                                    {totalPageNo > pageNo && <div className="text-center pt-2 mx-2">
                                                        <Link to={""} className='btn btn-primary btn-rounded' onClick={() => setPageNo(pageNo + 1)}>Next Page</Link>
                                                    </div>}
                                                    {pageNo !== 1 && <div className="text-center pt-2 mx-2">
                                                        <Link to={""} className='btn btn-primary btn-rounded' onClick={() => setPageNo(pageNo - 1)}>Previous Page</Link>
                                                    </div>}
                                                </div>



                                                <div className="button-wrap d-flex flex-wrap align-items-center justify-content-between">
                                                    <button className='btn btn-delete btn-rounded me-3 btn-300' onClick={() => deleteSubscription(route)}>End Selected Subscriptions</button>

                                                    <div className="alert alert-warning" role="alert">
                                                        Note: Subscriptions will remain active until the end of their billing period

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
    /* jshint ignore:end */
}
