import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import {getLocal,checkUserRoutePermission} from "../../helper/common";

export default function DetailsSubmenu({route}) {
    let role = getLocal("role");
    const params = useParams();
    return (
        <div className="position-relative">
            <div className='nav-head'>
                <ul className="nav nav-tabs header-tabs">
                   {checkUserRoutePermission(role,`/${route}/cameras/details/:device_uuid`) && <li className="nav-item" role="presentation">
                        <NavLink to={`/${route}/cameras/details/${params.device_uuid}`} className="nav-link">Details</NavLink>
                    </li> }
                    {checkUserRoutePermission(role,`/${route}/cameras/live-camera/:device_uuid`) && <li className="nav-item" role="presentation">
                        <NavLink to={`/${route}/cameras/live-camera/${params.device_uuid}`} className="nav-link">Live Camera</NavLink>
                    </li>
                    }
                    {checkUserRoutePermission(role,`/${route}/cameras/notifications/:device_uuid`) &&
                    <li className="nav-item" role="presentation">
                        <NavLink to={`/${route}/cameras/notifications/${params.device_uuid}`} className="nav-link">Notification</NavLink>
                    </li> }
                    {/* <li className="nav-item" role="presentation">
                        <NavLink to="/customer/recordings/notes" className="nav-link">Notes</NavLink>
                    </li> */}

                </ul>
            </div>
        </div>
    )
}
