import React from 'react'
import loadImg from '../assets/images/loading-gif.gif'
import { useUiContext } from '../contexts/UiContext'

function Loader() {
  const { loading } = useUiContext();
  return (
    <>

      {loading &&
        <div className='loader-div'>
          <div className="multi-spinner-container">
            <div className="multi-spinner">
              <div className="multi-spinner">
                <div className="multi-spinner">
                  <div className="multi-spinner">
                    <div className="multi-spinner">
                      <div className="multi-spinner">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={loadImg} alt="loader"  className="loader"/> */}
        </div>
      }
    </>
  )
}

export default Loader
