import React from 'react'
import HeaderDashboard from '../common/HeaderDashboard'
import ResellerHeader from '../common/ResellerHeader'

export default function DevicesStorageSummary() {
    return (
        <>


            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <ResellerHeader />
                        <div className="flex-grow-1 setting-page-wrapper">
                            <div className="container">
                                <div className='user-permissions usr-prm devices-storage-wrapper'>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Customer</th>

                                                            <th scope="col" className="text-center">Device Is Active </th>
                                                            <th scope="col" className="text-center">Device Count</th>
                                                            <th scope="col" className="text-center">Recording Storage</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>test customer one</td>
                                                            <td className="text-center">Yes</td>
                                                            <td className="text-center">14</td>
                                                            <td className="text-center">123 GB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">140</td>
                                                            <td className="text-center">2100 GB</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
