import React, { useEffect, useRef, useState } from 'react'
import axios from "axios"

import HeaderDashboard from '../common/HeaderDashboard'
import { baseUrl } from "../../constants/common"
import { useUiContext } from '../../contexts/UiContext'

export default function Terms() {
    const { apiRequest } = useUiContext();

    const termsDivTag = useRef();
    const [innerHtml, setInnerHtml] = useState("");

    useEffect(() => {
        termsDivTag.current.innerHTML = innerHtml;
    }, [innerHtml])

    const apiRequestFn = async () => {
        const res = await apiRequest("get", "api_auth/1/login", "", {}, true);
        if (res) {
            setInnerHtml(res.data.terms_html);
        }
    }

    useEffect(() => {

        apiRequestFn()
    }, [])

    return (
        <div className='d-flex flex-column site-main-wrapper content-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height content-page d-flex flex-column">
                    <div className="page-title text-center">
                        <div className="container">
                            <h1>Terms and Conditions</h1>
                        </div>
                    </div>
                    <div className="flex-grow-1 content-page-wrapper">
                        <div className="container">
                            <div className="section-text" ref={termsDivTag}> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
