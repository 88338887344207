import React from 'react';

export default function ToggleDark(props) {
    return (
        <label className="switch">
            <input
                type="checkbox"
                id="checkbox-toggle"
                defaultChecked = {props.darkMode}
                onClick={() => {
                    props.toggleDark();
                }}
            />
            <span className="slider round"></span>


        </label>
    );
}