import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDashboard from '../../common/HeaderDashboard'
import ResellerHeader from '../../common/ResellerHeader'
import Svg from '../../common/Svg'


export default function Adduser() {
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <ResellerHeader />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            <div className="user-permissions">
                                <Link to="/reseller/user-summary" className="back-btn d-inline-flex align-items-center">
                                    <Svg name="left-arrow" /> Back
                                </Link>
                                <div className="create-user">
                                    <div className="page-heading">
                                        <h3>Create new user</h3>
                                    </div>
                                    <div className="create-user-wrapper">
                                        <div className="form-wrapper theme-form-wrapper">
                                            <form>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <input type="text" placeholder="Your Name" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <input type="email" placeholder="Enter your email" className="form-control" />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 form-check">
                                                    <input type="checkbox" className="form-check-input" id="makeAdmin" />
                                                    <label className="form-check-label" htmlFor="makeAdmin">Make this user Admin</label>
                                                </div>


                                                <div className="button-wrap text-center text-lg-start">
                                                    <button className="btn btn-primary btn-rounded" disabled>Create</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/*use this if required */}
                                <div className="existing-user d-none">


                                    <div className="subscription-group row">
                                        <div className="col-lg-4">
                                            <div className="subscription-box">
                                                <div className="heading">
                                                    <h4>Corey Meloy</h4>
                                                    <p>Seller-admin-test@xyz.com</p>
                                                </div>
                                                <div className="data-wrapper">
                                                    <div className="data-list">
                                                        <div className="title">Admin</div>
                                                        <div className="date-wrap text-primary">Yes</div>
                                                    </div>
                                                    <div className="data-list">
                                                        <div className="title">Active</div>
                                                        <div className="date-wrap text-primary">Yes</div>
                                                    </div>
                                                    <div className="data-list">
                                                        <div className="title">Last Login</div>
                                                        <div className="date-wrap">4 min ago</div>
                                                    </div>

                                                </div>
                                                <div className="button-wrap text-center">
                                                    <button className='btn btn-delete btn-rounded'>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="subscription-box">
                                                <div className="heading">
                                                    <h4>Corey Meloy</h4>
                                                    <p>Seller-admin-test@xyz.com</p>
                                                </div>
                                                <div className="data-wrapper">
                                                    <div className="data-list">
                                                        <div className="title">Admin</div>
                                                        <div className="date-wrap text-primary">Yes</div>
                                                    </div>
                                                    <div className="data-list">
                                                        <div className="title">Active</div>
                                                        <div className="date-wrap text-primary">Yes</div>
                                                    </div>
                                                    <div className="data-list">
                                                        <div className="title">Last Login</div>
                                                        <div className="date-wrap">4 min ago</div>
                                                    </div>

                                                </div>
                                                <div className="button-wrap text-center">
                                                    <button className='btn btn-delete btn-rounded'>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="subscription-box">
                                                <div className="heading">
                                                    <h4>Corey Meloy</h4>
                                                    <p>Seller-admin-test@xyz.com</p>
                                                </div>
                                                <div className="data-wrapper">
                                                    <div className="data-list">
                                                        <div className="title">Admin</div>
                                                        <div className="date-wrap text-primary">Yes</div>
                                                    </div>
                                                    <div className="data-list">
                                                        <div className="title">Active</div>
                                                        <div className="date-wrap text-primary">Yes</div>
                                                    </div>
                                                    <div className="data-list">
                                                        <div className="title">Last Login</div>
                                                        <div className="date-wrap">4 min ago</div>
                                                    </div>

                                                </div>
                                                <div className="button-wrap text-center">
                                                    <button className='btn btn-delete btn-rounded'>Remove</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
