import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HeaderDashboard from "../../../common/HeaderDashboard";
import SettingNavigation from "../../../common/SettingNavigation";
import { useUiContext } from "../../../../contexts/UiContext";
import { useState } from "react";
import Svg from "../../../common/Svg";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN } from "../../../../constants/common";
import { getLocal } from "../../../../helper/common";

const SitesList = () => {
  const params = useParams();
  const { apiRequest } = useUiContext();
  const [sitesList, setSitesList] = useState([]);
  let address_uuid = params.address_uuid;
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const getSitesList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest(
      "get",
      `api/1/sitemaps/?address_uuid=${address_uuid}`,
      "",
      headers,
      true
    )
      .then((res) => {
        let response = res.data;
        setSitesList(response.results);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  useEffect(() => {
    getSitesList();
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      "pdfjs-dist/build/pdf.worker.min.js",
      import.meta.url
    ).toString();
  }, []);

  function onDocumentLoadSuccess(e) {
    setNumPages(e.numPages);
  }

  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="main-page-height settings-page d-flex flex-column">
        <SettingNavigation />
        <div className="flex-grow-1 setting-page-wrapper">
          <div className="container">
            <div className="user-permissions">
              <div className="existing-user mx-auto">
                <div className="subscription-group row address-list">
                  {sitesList.length>0 ?<>
                  {sitesList.map((site, index) => (
                    <div className="col-lg-6 mb-4" key={index}>
                      <div className="subscription-box">
                        <div className="heading">
                          <div className="d-flex heading-wrap mb-1">
                            <div className="flex-grow-1">
                              <h4>
                                {site.name}{" "}
                                {site.default_sitemap === true && (
                                  <small>(Default)</small>
                                )}
                              </h4>
                            </div>
                            {[ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(getLocal('userRole')) && (
                              <Link
                              to={`/customer/sites/${address_uuid}/site/${site.sitemap_uuid}`}
                              className="edit-btn"
                            >
                              <Svg name="edit-details" />
                            </Link>
                            )}
                          </div>
                        </div>
                        {site.image_map ? (
                          <div className="data-wrapper d-flex justify-content-center">
                            {site.image_map.includes("pdf") ? (
                              <Document
                                file={`${site.image_map}`}
                                onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
                              >
                                <Page pageNumber={pageNumber}></Page>
                              </Document>
                            ) : (
                              <img className="site-img" src={site.image_map} />
                            )}
                          </div>
                        ) : (
                          <div>site map not uploaded.</div>
                        )}
                        <div className="button-wrap text-center">
                          {/* {res.default_location !==true && <button className='btn btn-delete btn-rounded' data-bs-toggle="modal" data-bs-target="#endSubscription" onClick={()=>setIndex(index)}>Delete Address</button>}
                                                    <Link to={`/customer/sites/${res.address_uuid}/site`} className='edit-btn'>
                                                        <button type="button" className="btn btn-primary btn-rounded">View Site</button>
                                                    </Link> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  </>:
                  <div className="d-flex justify-content-center">
                    <h1>No Site Maps Loaded</h1>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SitesList;
