import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Svg from './Svg'
import { checkUserRoutePermission, getLocal } from "../../helper/common"
import { ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER } from '../../constants/common';

export default function SettingNavigation() {

    const [selectedItem, setSelectedItem] = useState(null);
    const role = getLocal('role');
    const userRole = getLocal('userRole');
    const location = useLocation();
  
    useEffect(() => {
      const { pathname } = location;
      const selectedItem = document.querySelector(`.settings-header li a[href="${pathname}"]`);
      if (selectedItem) {
        setSelectedItem(selectedItem);
        selectedItem.scrollIntoView({ behavior: 'smooth' });
      }
    }, [location]);

    const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;
    return (
        <div className='position-relative'>
            <div className="settings-header">
                <ul>
                    {/* <li className={(!checkUserRoutePermission(role,"abc"))?"d-none":""}> */}
                    {checkUserRoutePermission(role, "/customer/profile-settings") && <li>
                        <NavLink to="/customer/profile-settings" ><Svg name="general-setting" /> General</NavLink>
                    </li>}
                    {checkUserRoutePermission(role, "/reseller/profile-settings") && <li>
                        <NavLink to="/reseller/profile-settings" ><Svg name="general-setting" /> General</NavLink>
                    </li>}
                    {checkUserRoutePermission(role, "/distributor/profile-settings") && <li> 
                        <NavLink to="/distributor/profile-settings" ><Svg name="general-setting" /> General</NavLink>
                    </li>}
                    {checkUserRoutePermission(role, "/customer/account-details") && <li>
                        <NavLink to="/customer/account-details"><Svg name="account-setting" /> Account details</NavLink>
                    </li>}
                    {checkUserRoutePermission(role, "/reseller/account-details") && <li>
                        <NavLink to="/reseller/account-details"><Svg name="account-setting" /> Account details</NavLink>
                    </li>}
                    {checkUserRoutePermission(role, "/distributor/account-details") && <li>
                        <NavLink to="/distributor/account-details"><Svg name="account-setting" /> Account details</NavLink>
                    </li>}
               
                    { isAdmin ? checkUserRoutePermission(role, "/customer/billable-subscriptions") && <li >
                        <NavLink to="/customer/billable-subscriptions"><Svg name="billable-setting" /> Billable Subscriptions </NavLink>
                    </li> : null}
                    { isAdmin ? checkUserRoutePermission(role, "/reseller/billable-subscriptions") && <li >
                        <NavLink to="/reseller/billable-subscriptions"><Svg name="billable-setting" /> Billable Subscriptions </NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/distributor/billable-subscriptions") && <li >
                        <NavLink to="/distributor/billable-subscriptions"><Svg name="billable-setting" /> Billable Subscriptions </NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/reseller/unassigned-subscriptions-summary") && <li>
                        <NavLink to="/reseller/unassigned-subscriptions-summary"><Svg name="billable-setting" /> Assign Subscriptions</NavLink>
                    </li> : null }
                    {checkUserRoutePermission(role, "/distributor/unassigned-subscriptions-summary") && <li>
                        <NavLink to="/distributor/unassigned-subscriptions-summary"><Svg name="billable-setting" /> Assign Subscriptions</NavLink>
                    </li>}
                    { isAdmin ? checkUserRoutePermission(role, "/customer/devices-and-storage") &&  <li>
                        <NavLink to="/customer/devices-and-storage"><Svg name="device-setting" /> Devices and Storage </NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/reseller/devices-and-storage") &&  <li>
                        <NavLink to="/reseller/devices-and-storage"><Svg name="device-setting" /> Devices and Storage </NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/distributor/devices-and-storage") &&  <li>
                        <NavLink to="/distributor/devices-and-storage"><Svg name="device-setting" /> Devices and Storage </NavLink>
                    </li> : null }
                    { [ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, 
                        MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, 
                            SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER].includes(userRole) && 
                        <li>
                            <NavLink to="/customer/users-and-permissions"><Svg name="users-setting" /> Users and Permissions</NavLink>
                        </li>
                    }
                    {checkUserRoutePermission(role, "/customer/sites") && <li>
                        <NavLink to="/customer/sites"><Svg name="addresses" /> Sites</NavLink>
                    </li> }
                    { isAdmin ? checkUserRoutePermission(role, "/reseller/sites") && <li>
                        <NavLink to="/reseller/sites"><Svg name="addresses" /> Sites</NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/distributor/sites") && <li>
                        <NavLink to="/distributor/sites"><Svg name="addresses" /> Sites</NavLink>
                    </li> : null }
                    {isAdmin ?  checkUserRoutePermission(role, "/distributor/users-and-permissions") && <li>
                        <NavLink to="/distributor/users-and-permissions"><Svg name="users-setting" /> Users and Permissions</NavLink>
                    </li>:null}
                    {isAdmin ?  checkUserRoutePermission(role, "/reseller/users-and-permissions") && <li>
                        <NavLink to="/reseller/users-and-permissions"><Svg name="users-setting" /> Users and Permissions</NavLink>
                    </li>:null}
                    { isAdmin ? checkUserRoutePermission(role, "/customer/resellers-system-integrators") && <li>
                        <NavLink to="/customer/resellers-system-integrators"><Svg name="system-setting" /> Resellers / System Integrators</NavLink>
                    </li> : null }
                    {isAdmin ? checkUserRoutePermission(role, "/reseller/resellers-system-integrators") && <li>
                        <NavLink to="/reseller/resellers-system-integrators"><Svg name="system-setting" /> Distributors / Customers</NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/distributor/resellers-system-integrators") && <li>
                        <NavLink to="/distributor/resellers-system-integrators"><Svg name="system-setting" /> Resellers / System Integrators</NavLink>
                    </li> : null}
                    { isAdmin ? checkUserRoutePermission(role, "/customer/reports-and-analytics") && <li>
                        <NavLink to="/customer/reports-and-analytics"><Svg name="reports-setting" /> Reports and Analytics</NavLink>
                    </li>: null }
                    { isAdmin ? checkUserRoutePermission(role, "/reseller/reports-and-analytics") && <li>
                        <NavLink to="/reseller/reports-and-analytics"><Svg name="reports-setting" /> Reports and Analytics</NavLink>
                    </li> : null }
                    { isAdmin ? checkUserRoutePermission(role, "/distributor/reports-and-analytics") && <li>
                        <NavLink to="/distributor/reports-and-analytics"><Svg name="reports-setting" /> Reports and Analytics</NavLink>
                    </li> : null }
                </ul>
            </div>
        </div>
    )
}

