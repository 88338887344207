import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/timeline.css";
import ThemeContextWrapper from "./theme/ThemeWrapper";
import {UiContext} from "./contexts/UiContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
/* jshint ignore:start */
root.render(
    <ThemeContextWrapper>
        <UiContext>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </UiContext>
    </ThemeContextWrapper>
);
/* jshint ignore:end */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
