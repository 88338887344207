import React from 'react'
import HeaderDashboard from '../common/HeaderDashboard'
import ResellerHeader from '../common/ResellerHeader'
import Svg from '../common/Svg'

export default function Details() {
    return (
        <>
            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <ResellerHeader />
                        <div className="flex-grow-1 setting-page-wrapper">

                            <div className="container">
                                <form className='profile-wrapper acccount-details-form'>
                                    <div className="row theme-form-wrapper">
                                        <div className="col-lg-6">
                                            <div className="account-form timezone-form p-0">
                                                <div className="d-flex mb__20">
                                                    <label htmlFor="name" className="col-form-label text-end">Name</label>
                                                    <div className="flex-grow-1">
                                                        <input type="text" className="form-control" id="name" placeholder='Billing test customer one' />
                                                    </div>
                                                </div>
                                                <div className="d-flex mb__20">
                                                    <label htmlFor="abn" className="col-form-label text-end">ABN</label>
                                                    <div className="flex-grow-1">
                                                        <input type="text" className="form-control" id="abn" placeholder='34 784 206 473' />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="recording-form">
                                                <div className="d-flex mb__20 justify-content-end">
                                                    <label htmlFor="Timezone" className="col-form-label text-end">Billing Contact Name</label>
                                                    <div className="flex-grow-1">
                                                        <input type="text" className="form-control" id="Timezone" placeholder='Billing Contact Name' />
                                                    </div>
                                                </div>
                                                <div className="d-flex mb__20 justify-content-end">
                                                    <label htmlFor="Email" className="col-form-label text-end">Billing Contact Email</label>
                                                    <div className="flex-grow-1">
                                                        <input type="email" className="form-control" id="Email" placeholder='youremail@mail.com' />
                                                    </div>
                                                </div>
                                                

                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <hr className='custom-margin' />
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="recording-form">

                                                <div className="d-flex mb__20 justify-content-end">
                                                    <label htmlFor="Distributors" className="col-form-label text-end">Distributors</label>
                                                    <div className="flex-grow-1">
                                                        <input type="text" className="form-control" id="Distributors" placeholder='Distributors' />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="button-wrap pt-lg-5 text-center">
                                                <button className='btn btn-primary btn-rounded'>Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
