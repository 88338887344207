import React ,{ useState, useEffect }  from 'react'
import { Helmet } from "react-helmet";
import { useUiContext } from '../../contexts/UiContext';

function HeaderMetaData() {

  const { headerData } = useUiContext();
  const [ headData, sethead ] = useState({title:'',description:''});

  useEffect(() => {
    sethead(headerData)
  },[headerData])
  return (
    <div>
      <Helmet>
        <title> {headData.title} </title>
        <meta
      name="description"
      content={headData.description} 
    />
      </Helmet>
    </div>
  )
}

export default HeaderMetaData