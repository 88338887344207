import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderDashboard from "../../../common/HeaderDashboard";
import SettingNavigation from "../../../common/SettingNavigation";
import { useUiContext } from "../../../../contexts/UiContext";
import { getLocal, checkUserRoutePermission } from "../../../../helper/common";
import moment from "moment-timezone";
import { toast } from "react-hot-toast";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CUSTOMER_ADMIN, DISTRIBUTOR_ADMIN, ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, SELLER_ADMIN, SINGLE, SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER } from "../../../../constants/common";

export default function UsersPermissions({ route }) {
  let role = getLocal("role");
  let userRole = getLocal('userRole');
  let uid = localStorage.getItem("customeruuid");
  const { apiRequest } = useUiContext();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [userServerParams, setUserServerParams] = useState({
    search: "",
    page: 1,
    page_size: 10,
  });
  const [totalPageNo, setTotalPageNo] = useState(1);
  const [pageValue, setPageValue] = useState("");

  const [selectedUserId, setSelectedUserId] = useState(null);
  const timeZone = localStorage.getItem("tz");
  const endpoint =
    route === "customer"
      ? "customers"
      : route === "reseller"
      ? "sellers"
      : "distributors";
  const getUserData = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "get",
      `api/1/${endpoint}/${uid}/get_summary_users_for/`,
      "",
      headers,
      true
    );
    if (res) {
      setData(res.data.rows);
    }
  };

  const handlePageValueChange = (e) => {
    setPageValue(e.target.value);
  };

  function handlePageValueKeyDown(event) {
    handlePageValueChange(event);
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        setUserServerParams({ ...userServerParams, page: pageValue });
        break;
    }
  }
  function handlePageValueFocusout(event) {
    handlePageValueChange(event);
    if (event.target.value) {
      setUserServerParams({ ...userServerParams, page: pageValue });
    } else {
      setUserServerParams({ ...userServerParams, page: 1 });
    }
  }

  const getUsers = async (loader) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "get",
      `api/1/${
        endpoint === "sellers"
          ? "seller-users"
          : endpoint === "distributors"
          ? "distributor-users"
          : "users"
      }/`,
      // `api/1/users/`,
      "",
      headers,
      loader,
      {},
      userServerParams
    );
    if (res) {
      setUsers(res.data.results);
      setTotalPageNo(res.data.page_count);
    }
  };

  const userInactive = async (id, active) => {
    const endpoints =
    route === "customer"
      ? "users"
      : route === "reseller"
      ? "seller-users"
      : "distributor-users";
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "patch",
      `api/1/${endpoints}/${users[id].username}/`,
      { is_active: active ? false : true },
      headers,
      true,
      { success: "Updated Successfully", error: "User Not Updated" }
    );
    if (res) {
      getUsers(true)
    }
  };

  const resetPassword = async (user) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    try {
      await apiRequest(
        "post",
        `api_auth/1/password_reset`,
        { email: user.email },
        headers,
        true,
        { error: "Email not sent" }
      ).then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
        }
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const sendWelcomeMail = async (user) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
      Accept: "application/json",
    };
    await apiRequest(
      "post",
      `api_auth/1/resend_welcome`,
      { email: user.email },
      headers,
      false
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
        }
      })
      .catch((error) => {
        toast.error("Email not sent");
        console.log("error", error);
      });
  };

  const deleteUser = async (username) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    try {
      apiRequest(
        "delete",
        `api/1/${ endpoint === "sellers" ? "seller-users"
        : endpoint === "distributors" ? "distributor-users"
        : "users" }/${username}/`,
        {},
        headers,
        true,
        false
      ).then((res) => {
        if(res.status === 204){
          toast.success('User deleted successfully');
          getUserData();
          getUsers(false);
        }else if(res.response.status === 403){
          toast.error(res.response.data.detail);
        }else{
          toast.error('Some error occured, try again after some time')
        }
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getUsers(false);
  }, [userServerParams]);

  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height settings-page d-flex flex-column">
          <SettingNavigation />
          <div className="flex-grow-1 setting-page-wrapper">
            <div className="container">
              <div className="user-permissions usr-prm devices-storage-wrapper">
                <div className="devices-storage-list">
                  <div className="theme-table">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          {data.map((rows, i) => (
                            <tr key={i}>
                              {i == 0 &&
                                rows.map((row, index) => (
                                  <th key={index}>{row} </th>
                                ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {data.map((rows, i) => (
                            <tr key={i}>
                              {i > 0 &&
                                rows.map((row, index) => (
                                  <td key={index}>{row} </td>
                                ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {([ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(userRole) || 
                    ([CUSTOMER_ADMIN, DISTRIBUTOR_ADMIN, SELLER_ADMIN].includes(role))) && (
                    <div className="button-wrap text-center pt-lg-4 pt-3">
                      <Link
                        to={`/${route}/users-and-permissions/create`}
                        className="btn btn-secondary btn-rounded btn-300"
                      >
                        Create User
                      </Link>
                    </div>
                  )}
                </div>
                <div className="devices-storage-list">
                  <input
                    type="text"
                    className="form-control mb-5"
                    placeholder="Search"
                    defaultValue={userServerParams.search}
                    onChange={(e) => {
                      setUserServerParams({
                        ...userServerParams,
                        search: e.target.value,
                      });
                    }}
                  />

                  <div className="theme-table">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">User Name</th>
                            <th scope="col">Access Level</th>
                            <th scope="col">Last Login</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 ? (
                            users.map((user, userIndex) => (
                              <tr key={userIndex}>
                                <td
                                  className={user.is_active ? "" : "inactive"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSelectedUserId(userIndex + 1);
                                  }}
                                >
                                  {user.first_name} {user.last_name}
                                </td>
                                {user.customer_profile && (
                                  <td
                                    className={
                                      user.is_active
                                        ? "text-capitalize"
                                        : "inactive text-capitalize"
                                    }
                                  >
                                    {user.customer_profile.role === null ||
                                    user.customer_profile.role === ""
                                      ? "-"
                                      : user.customer_profile.role.replaceAll(
                                          "_",
                                          " "
                                        )}
                                  </td>
                                )}
                                {user.distributor_profile && (
                                  <td
                                    className={
                                      user.is_active
                                        ? "text-capitalize"
                                        : "inactive text-capitalize"
                                    }
                                  >
                                    {user.distributor_profile
                                      .is_distributor_admin
                                      ? "Distributor Admin"
                                      : "Distributor User"}
                                  </td>
                                )}
                                {user.seller_profile && (
                                  <td
                                    className={
                                      user.is_active
                                        ? "text-capitalize"
                                        : "inactive text-capitalize"
                                    }
                                  >
                                    {user.seller_profile.is_seller_admin
                                      ? "Seller Admin"
                                      : "Seller User"}
                                  </td>
                                )}
                                <td
                                  className={user.is_active ? "" : "inactive "}
                                >
                                  {user.last_login === null
                                    ? "-"
                                    : moment
                                        .tz(user.last_login, timeZone)
                                        .format("DD/MM/YYYY")}
                                </td>
                                {/* {selectedUserId &&
                                  selectedUserId === userIndex + 1 && ( */}
                                <td>
                                  <div className="button-wrap">
                                    {([
                                      SELLER_ADMIN,
                                      DISTRIBUTOR_ADMIN,
                                    ].includes(role) || [ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, 
                                        MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, 
                                          SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER].includes(userRole)) && 
                                  (
                                      <>
                                        <Link
                                          data-tooltip-id={`${user.username}-${userIndex}`}
                                          to={`/${route}/users-and-permissions/edit/${user.username}`}
                                          className="btn btn-primary btn-rounded edit"
                                        >
                                          Edit
                                        </Link>
                                        <ReactTooltip
                                          variant="success"
                                          id={`${user.username}-${userIndex}`}
                                          place="top"
                                          content="Edit"
                                        />
                                      </>
                                    )}
                                    {[
                                      SELLER_ADMIN,
                                      DISTRIBUTOR_ADMIN,
                                      CUSTOMER_ADMIN
                                    ].includes(role) && (
                                      <button
                                        data-tooltip-id={`${user.username}-${
                                          userIndex + 1
                                        }`}
                                        className={
                                          user.is_active
                                            ? "disable btn btn-delete-new btn-rounded"
                                            : "enable btn btn-delete-new btn-rounded"
                                        }
                                        onClick={() => {
                                          userInactive(
                                            userIndex,
                                            user.is_active
                                          );
                                        }}
                                      >
                                        Disabled
                                      </button>
                                    )}
                                    <ReactTooltip
                                      variant="success"
                                      id={`${user.username}-${userIndex + 1}`}
                                      place="top"
                                      content={
                                        user.is_active
                                          ? "Disable"
                                          : "Re-activate"
                                      }
                                    />
                                    {[ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER,
                                      MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER,
                                        SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER
                                      ].includes(userRole) && (
                                      <button
                                        data-tooltip-id={`${user.username}-${
                                          userIndex + 2
                                        }`}
                                        className="btn btn-danger btn-rounded delete"
                                        onClick={() => {
                                          deleteUser(user.username);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {[
                                      SELLER_ADMIN,
                                      DISTRIBUTOR_ADMIN,
                                    ].includes(role) && (
                                      <button
                                        data-tooltip-id={`${user.username}-${
                                          userIndex + 2
                                        }`}
                                        className="btn btn-danger btn-rounded delete"
                                        onClick={() => {
                                          deleteUser(user.username);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                    <ReactTooltip
                                      variant="success"
                                      id={`${user.username}-${userIndex + 2}`}
                                      place="top"
                                      content="Delete"
                                    />
                                    <button
                                      data-tooltip-id={`${user.username}-${
                                        userIndex + 3
                                      }`}
                                      className="btn  btn-secondary orange-color btn-rounded reset"
                                      onClick={() => resetPassword(user)}
                                    >
                                      Reset Password
                                    </button>
                                    <ReactTooltip
                                      variant="success"
                                      id={`${user.username}-${userIndex + 3}`}
                                      place="top"
                                      content="Reset Password"
                                    />
                                    {checkUserRoutePermission(
                                      role,
                                      `/${route}/users-and-permissions/resendWelcome`
                                    ) && (
                                      <>
                                        <button
                                          data-tooltip-id={`${user.username}-${
                                            userIndex + 4
                                          }`}
                                          className="btn btn-success btn-rounded resend"
                                          onClick={() => {
                                            sendWelcomeMail(user);
                                          }}
                                        >
                                          Resend Welcome Email
                                        </button>
                                        <ReactTooltip
                                          variant="success"
                                          id={`${user.username}-${
                                            userIndex + 4
                                          }`}
                                          place="top"
                                          content="Resend Welcome Email"
                                        />
                                      </>
                                    )}
                                  </div>
                                </td>
                                {/* )} */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} style={{ textAlign: "center" }}>
                                {" "}
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-md-flex flex-wrap justify-content-center page-nav">
                      {!users.length > 0 ||
                        (userServerParams.page > 1 && (
                          <div className="text-center pt-2 mx-2 ">
                            <Link
                              to={""}
                              className="btn btn-primary btn-rounded"
                              onClick={() =>
                                setUserServerParams({
                                  ...userServerParams,
                                  page: userServerParams.page - 1,
                                })
                              }
                            >
                              Previous Page
                            </Link>
                          </div>
                        ))}
                      {!users.length > 0 ||
                        (userServerParams.page < totalPageNo && (
                          <div className="text-center pt-2 mx-2 ">
                            <Link
                              to={""}
                              className="btn btn-primary btn-rounded"
                              onClick={() => {
                                setUserServerParams({
                                  ...userServerParams,
                                  page: userServerParams.page + 1,
                                });
                              }}
                            >
                              Next Page
                            </Link>
                          </div>
                        ))}
                      {!users.length > 0 ||
                        (userServerParams.page <= totalPageNo && (
                          <div className="pagination-btn text-center pt-2 mx-2">
                            <p className="no-underline heading">
                              Page {userServerParams.page} of {totalPageNo}
                            </p>
                          </div>
                        ))}
                      {!users.length > 0 ||
                        (userServerParams.page <= totalPageNo && (
                          <div className="d-flex pt-2 mx-2">
                            <p className="pt-3 mx-3 no-underline heading">
                              Go to Page#
                            </p>
                            <input
                              className="page-search form-control mr-sm-2"
                              type="search"
                              id="search"
                              placeholder="Search"
                              value={pageValue}
                              onChange={handlePageValueChange}
                              onKeyDown={handlePageValueKeyDown}
                              onBlur={handlePageValueFocusout}
                              aria-label="Search"
                            />
                            <button
                              className="search-btn btn btn-primary btn-rounded my-2 my-sm-0"
                              onClick={() => {
                                setUserServerParams({
                                  ...userServerParams,
                                  page: pageValue,
                                });
                              }}
                            >
                              Go
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
