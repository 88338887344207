import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import HeaderDashboard from "../../../common/HeaderDashboard";
import SettingNavigation from "../../../common/SettingNavigation";
import Svg from "../../../common/Svg";
import { getLocal, checkUserRoutePermission } from "../../../../helper/common";
import { ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN } from "../../../../constants/common";

export default function AddressList({ route }) {
  let role = getLocal("role");
  let userRole = getLocal("userRole");
  let path = useLocation();
  const [data, setData] = useState([]);
  const [index, setIndex] = useState("");
  const { apiRequest } = useUiContext();
  const getAddressList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest("get", "api/1/addresses/", "", headers, true);
    if (res) {
      setData(res.data.results);
    }
  };

  const deleteAddress = async (id) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "delete",
      `api/1/addresses/${data[id].address_uuid}/`,
      "",
      headers,
      true,
      { success: "deleted Successfully", error: "Address not deleted" }
    );
    if (res) {
      getAddressList();
    }
  };

  useEffect(() => {
    getAddressList();
  }, []);
  return (
    <>
      <div className="d-flex flex-column site-main-wrapper setting-body">
        <HeaderDashboard />
        <div className="flex-grow-1 main-wrapper">
          <div className="main-page-height settings-page d-flex flex-column">
            <SettingNavigation />
            <div className="flex-grow-1 setting-page-wrapper">
              <div className="container">
                <div className="user-permissions">
                  <div className="existing-user mx-auto">
                    <div className="subscription-group row address-list">
                      {/*Default Address Can not be deleted*/}
                      {data.map((res, index) => (
                        <div className="col-lg-6 mb-4" key={index}>
                          <div className="subscription-box">
                            <div className="heading">
                              <div className="d-flex heading-wrap mb-1">
                                <div className="flex-grow-1">
                                  <h4>
                                    {res.name}{" "}
                                    {res.default_location === true && (
                                      <small>(Default)</small>
                                    )}
                                  </h4>
                                </div>
                                {[ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(userRole) && 
                                  <Link
                                    to={`/customer/sites/edit?address_uuid=${res.address_uuid}`}
                                    className="edit-btn"
                                  >
                                    <Svg name="edit-details" />
                                  </Link>
                                }
                              </div>
                              <p>{res.address}</p>
                            </div>
                            <div className="data-wrapper">
                              <div className="data-list">
                                <div className="title">Latitude</div>
                                <div className="latlong-wrap">{res.lat}</div>
                              </div>
                              <div className="data-list">
                                <div className="title">Longitude</div>
                                <div className="latlong-wrap">{res.lon}</div>
                              </div>
                            </div>
                            <div className="button-wrap text-center">
                              { [ENTERPRISE_ADMIN].includes(userRole) && 
                                (res.default_location !== true && (
                                  <button
                                    className="btn btn-delete btn-rounded"
                                    data-bs-toggle="modal"
                                    data-bs-target="#endSubscription"
                                    onClick={() => setIndex(index)}
                                  >
                                    Delete Address
                                  </button>
                                ))
                              }
                              <Link
                                to={`/customer/sites/${res.address_uuid}/site`}
                                className="edit-btn"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary btn-rounded"
                                >
                                  View Site
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    { [ENTERPRISE_ADMIN].includes(userRole) && (
                        <div className="button-wrap text-center pt-lg-4 pt-3">
                          <Link
                            to={`/${route}/sites/create`}
                            className="btn btn-primary btn-rounded btn-300"
                          >
                            Create New Site
                          </Link>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-delete"
        id="endSubscription"
        tabIndex={-1}
        aria-labelledby="endSubscriptionLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-wrap">
                <h4>Are you sure you want to delete this address? </h4>
                <div className="note">
                  NOTE - Once deleted, you can not find this address in list
                </div>
              </div>
              <div className="button-wrap d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-delete-new"
                  onClick={() => deleteAddress(index)}
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
