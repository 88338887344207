import React from 'react'
import { Link } from 'react-router-dom'
import Svg from './Svg'

export default function ResellerHeader() {
    return (
        <>
            <div className='position-relative'>
                <div className="settings-header">
                    <ul>
                        <li>
                            <Link className='active' to="/reseller/details">
                                <Svg name="account-setting" /> Details</Link>
                        </li>
                        <li>
                            <Link to="/reseller/billable-subscriptions-summary">
                                <Svg name="billable-setting" /> Billable Subscriptions</Link>
                        </li>
                        <li>
                            <Link to="/reseller/unassigned-subscriptions-summary">
                                <Svg name="general-setting" /> Unassigned Subscriptions</Link>
                        </li>
                        <li>
                            <Link to="/reseller/devices-storage-summary">
                                <Svg name="device-setting" /> Devices and Storage</Link>
                        </li>
                        <li>
                            <Link to="/reseller/user-summary"> <Svg name="users-setting" />
                                Users and Permissions</Link>
                        </li>
                        <li>
                            <Link to="/reseller/distributor"><Svg name="system-setting" /> Distributors/Customers</Link>
                        </li>
                        <li>
                            <Link to="/reseller/subscription-reports"><Svg name="reports-setting" /> Subscription Report Downloads</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
