import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import HeaderAuthentication from '../common/HeaderAuthentication';
import {useUiContext} from '../../contexts/UiContext';
import {formValidation} from '../../helper/common';

export default function Signup() {
  const {loading, apiRequest} = useUiContext();
  const [disabled, setDisabled] = useState(true);
  const initialErrors = {
    abn: "",
    contact_email: "",
    contact_name: "",
    name: "",
    recording_fetch_start_time: "",
    recording_fetch_stop_time: "",
    sellers: "",
    timezone: "",
  };
  const [errors, setErrors] = useState({
    abn: "",
    contact_email: "",
    contact_name: "",
    name: "",
    recording_fetch_start_time: "",
    recording_fetch_stop_time: "",
    sellers: "",
    timezone: "",
  });
  const [sellers, setSellers] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    contact_email: "",
    contact_name: "",
    name: "",
    recording_fetch_start_time: "",
    recording_fetch_stop_time: "",
    selectedSeller: "",
    timezone: "",
  });
  // req for getting sellers list for sign up

  useEffect(() => {
    apiRequest("get", "api_auth/1/form_choices").then((res) => {
      setSellers(res.data.sellers);
      setTimezones(Object.entries(res.data.timezones));
      try {
        const newFormData = JSON.parse(localStorage.getItem("signUpFormData"));
        if (newFormData.recording_fetch_start_time === null) newFormData.recording_fetch_start_time = "";
        if (newFormData.recording_fetch_stop_time === null) newFormData.recording_fetch_stop_time = "";
        newFormData.selectedSeller = newFormData.sellers.length ? newFormData.sellers[0] : "";
        delete newFormData.sellers;
        setFormData(newFormData);
      } catch {
      }
      try {
        const errorsData = JSON.parse(localStorage.getItem("signUpFormErrors"));
        if (errorsData) {
          const newErrors = {...initialErrors};
          for (const k in errorsData) newErrors[k] = errorsData[k].join(", ");
          setErrors(newErrors);
        }
      } catch {
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {recording_fetch_start_time, recording_fetch_stop_time, ...newFormdata} = formData;
    formValidation(newFormdata, setDisabled);
  }, [formData]);


  const handleOnChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    const recording_fetch_start_time = formData.recording_fetch_start_time;
    const recording_fetch_stop_time = formData.recording_fetch_stop_time;

    const data = {
      "abn": formData.abn.split(" ").join(""),
      "contact_email": formData.contact_email,
      "contact_name": formData.contact_name,
      "name": formData.name,
      "recording_fetch_start_time": recording_fetch_start_time ? recording_fetch_start_time : null,
      "recording_fetch_stop_time": recording_fetch_stop_time ? recording_fetch_stop_time : null,
      "sellers": [
        JSON.parse(formData.selectedSeller)
      ],
      "timezone": formData.timezone,
      "resend": true
    };

    localStorage.removeItem("signUpFormErrors");
    localStorage.setItem("signUpFormData", JSON.stringify(data));
    const headers = {"Content-Type": "application/json"};

    function checkResult(res) {
      const errorsData = res?.response?.data?.errors;
      if (errorsData) {
        localStorage.setItem("signUpFormErrors", JSON.stringify(errorsData));
        let newErrors = {...initialErrors};
        let errorCount = 0;
        for (const k in errorsData) {
          const v = errorsData[k].join(", ");
          newErrors[k] = v ? v : "";
          errorCount += 1;
        }
        if (errorCount === 1 && errorsData.code) {
          navigate('/sign-up-verify');
        }
        setErrors(newErrors);
      } else if (res) {
        navigate('/sign-up-verify');
      }
    }
    apiRequest("post", "api_auth/1/customer_signup", data, headers).then(checkResult).catch(checkResult);
  };


  /* jshint ignore:start */
  return (
      <div className='d-flex flex-column site-auth-wrapper'>

        <HeaderAuthentication/>
        <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
          <div className="container">
            {loading && <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            <div className="form-wrapper">
              <div className="form-heading text-center">
                <h3>Sign up in seconds</h3>
                <p>Use your work email to log in or get started</p>
              </div>
              <div className="field-wrapper">

                <div className="mb__20 position-relative">
                  <input type="text" className="form-control" name="name" placeholder="Enter Name" value={formData.name}
                         onChange={handleOnChange}/>
                  {errors.name !== '' &&
                      <div className="validation-msg"
                           onClick={() => setErrors({...errors, name: ''})}
                      >{errors.name}</div>}
                </div>
                <div className="mb__20 position-relative">
                  <input type="text" className="form-control" name="contact_name" placeholder="Contact Name"
                         value={formData.contact_name} onChange={handleOnChange}/>
                  {errors.contact_name !== '' &&
                      <div className="validation-msg"
                           onClick={() => setErrors({...errors, contact_name: ''})}
                      >{errors.contact_name}</div>}
                </div>
                <div className="mb__20 position-relative">
                  <input type="text" className="form-control" name="contact_email" placeholder="Email Address"
                         value={formData.contact_email} onChange={handleOnChange}/>
                  {errors.contact_email !== '' &&
                      <div className="validation-msg"
                           onClick={() => setErrors({...errors, contact_email: ''})}
                      >{errors.contact_email}</div>}
                </div>
                <div className="mb__20 position-relative">
                  <input type="text" className="form-control" placeholder="Enter ABN" name="abn" value={formData.abn}
                         onChange={handleOnChange}/>
                  {errors.abn !== '' &&
                      <div className="validation-msg"
                           onClick={() => setErrors({...errors, abn: ''})}
                      >{errors.abn}</div>}
                </div>
                <div className="mb__20 position-relative">
                  <select className="form-select" name="timezone" onChange={handleOnChange}>
                    <option value="">Timezone</option>
                    {timezones?.map((timezone) =>
                        <option value={timezone[0]} key={timezone[0]
                        }>{timezone[1]}</option>
                    )}
                  </select>
                </div>

                <div className="mb__20 position-relative">
                  <input type="text" className="form-control" placeholder="Recording Sync Start Time Ex:- 13:15:00"
                         name="recording_fetch_start_time" value={formData.recording_fetch_start_time} onChange={handleOnChange}/>
                  {errors.recording_fetch_start_time !== '' &&
                      <div className="validation-msg"
                           onClick={() => setErrors({...errors, recording_fetch_start_time: ''})}
                      >{errors.recording_fetch_start_time}</div>}
                  <div className="form-text">If start is after stop fetch between: Start to Midnight otherwise start to Stop. If
                    empty, fetch anytime
                  </div>
                </div>
                <div className="mb__20 position-relative">
                  <input type="text" className="form-control" placeholder="Recording Sync Stop Time Ex:- 17:15:00"
                         name="recording_fetch_stop_time" value={formData.recording_fetch_stop_time} onChange={handleOnChange}/>
                  {errors.recording_fetch_stop_time !== '' &&
                      <div className="validation-msg"
                           onClick={() => setErrors({ ...errors, recording_fetch_stop_time: '' })}
                      >{errors.recording_fetch_stop_time}</div>}
                  <div className="form-text">If Stop is Before Start fetch between: Midnight to stop otherwise Start to Stop. If
                    empty fetch any time
                  </div>
                </div>
                <div className="mb__20 position-relative">
                  <select className="form-select" name="selectedSeller" onChange={handleOnChange}>
                    <option value="">Seller</option>
                    {sellers?.map((seller) =>
                        <option value={JSON.stringify(seller)}
                                key={seller.seller_uuid}>{seller.name}</option>
                    )}
                  </select>

                </div>
                {/* from complete end */}
              </div>
              <div className="button-wrap">
              <span>
                <button className='btn btn-primary' onClick={handleSubmit} disabled={disabled}>Continue</button>
              </span>
              </div>
              <div className="form-text text-center">
                <p>Already have an account? <Link to="/login">Log in</Link></p>
              </div>
            </div>
            <div className="outer-form-text">
              <p>By continuing, you agree to <Link to="https://www.eyefi.com.au/eyefi-cloud-connect-termsandconditions">Terms of Use</Link> Read our <Link to="/privacy-policy">Privacy
                Policy</Link></p>
            </div>
            .
          </div>
        </div>
      </div>
  )
  /* jshint ignore:end*/
}
