import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import {tileAttributionHtml, tileUrl} from "../../../constants/common";

const SiteView = ({ latlogArray, addressesNames }) => {
  const middleIndex = Math.floor(latlogArray.length / 2);
  const centerP0sition = latlogArray[middleIndex];
  const navigate = useNavigate();
  
  return (
    <div className="d-flex justify-content-center" style={{zIndex:1}}>
      <div className="map-wrap inline mb__20 w-50 map-view-resize">
        <MapContainer center={centerP0sition} zoom={13} scrollWheelZoom={true}>
          <TileLayer url={tileUrl} attribution={tileAttributionHtml} />
          {latlogArray.map((coord, index) => (
            <Marker
              key={index}
              position={coord}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                click: (e) => {
                  navigate(`/customer/cameras/sites/map-view/${coord[2]}`);
                },
              }}
            >
              <Popup>
               {addressesNames[index]}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default SiteView;
