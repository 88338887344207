import { useEffect, useRef } from "react";

export default function Iframe() {
  const iframeRef = useRef(null);
  useEffect(() => {
    const iframe = iframeRef.current;

    console.log("iframe", iframe);
    if (iframe) {
      const onLoad = () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const leftPanel = iframeDocument.getElementsByTagName("aside");
        const topPanel = iframeDocument.getElementsByTagName("header");
        console.log("testing ===========", topPanel, leftPanel);

        if (leftPanel) {
          leftPanel.style.display = "none";
        }
        if (topPanel) {
          topPanel.style.display = "none";
        }
      };

      iframe.onload = onLoad;

      return () => {
        iframe.onload = null;
      };
    }
  }, []);
  return (
    <iframe
      ref={iframeRef}
      src={`https://B8A44F46E064-173-D461D73C-y.staging.connect.eyefi.com.au:3328/camera/index.html#/recordings`}
      title={"testname"}
      height={"100%"}
      width={"100%"}
    />
  );
}
