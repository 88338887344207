import React, {useContext, createContext, useEffect} from 'react';

import {useNavigate, useLocation, useParams} from "react-router-dom";
import {isUserLoggedIn, getLocal} from "../helper/common";
import {
  customerAdminPaths, distributorAdminPaths, customerPaths, sellerAdminPaths, sellerPaths, distributorPaths
} from "../constants/allowedpath";

const Context = createContext(null);

function PrivateRoutes(props) {
  const {Component} = props;
  const navigate = useNavigate();
  const params = useLocation();
  const paramsKey = useParams();
  const route = params.pathname.split('/')[1];

  useEffect(() => {
    let key = Object.keys(paramsKey);
    let currentPath = params.pathname;
    for (let index = 0; index < key.length; index++) {
      currentPath = currentPath.replace(paramsKey[key[index]], ':' + key[index]);
    }
    if (!isUserLoggedIn()) {
      navigate('/');
      return;
    }

    const role = getLocal("role");
    switch (role) {
      case "customerIsAdmin":
        if (customerAdminPaths.indexOf(currentPath) === -1) {
          navigate(customerAdminPaths[0]);
        }
        break;
      case "customer":
        if (customerPaths.indexOf(currentPath) === -1) {
          navigate(customerPaths[0]);
        }
        break;
      case "distributorIsAdmin":
        if (distributorAdminPaths.indexOf(currentPath) === -1) {
          navigate(distributorAdminPaths[0]);
        }
        break;
      case "distributor":
        if (distributorPaths.indexOf(currentPath) === -1) {
          navigate(distributorPaths[0]);
        }
        break;
      case "sellerIsAdmin":
        if (sellerAdminPaths.indexOf(currentPath) === -1) {
          navigate(sellerAdminPaths[0]);
        }
        break;
      case "seller":
        if (sellerPaths.indexOf(currentPath) === -1) {
          navigate(sellerPaths[0]);
        }
        break;
      default:
    }
  });
  /* jshint ignore:start */
  return (
      <Context.Provider value={{route}}>
        <Component route={route}/>
      </Context.Provider>
  )
  /* jshint ignore:end */
}

export default PrivateRoutes;

export const useRouteContext = () => useContext(Context);

