import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import HeaderAuthentication from '../../common/HeaderAuthentication';
import {useUiContext} from '../../../contexts/UiContext';

export default function VerifyEmail() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const {apiRequest} = useUiContext();


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    async function submitHandler(e) {
        e.preventDefault();
        if (validateEmail(email)) {
            const headers = {
                'Content-Type': 'application/json',
            };
            await apiRequest("post", "api_auth/1/password_reset", {email: email}, headers, true, {
                success: 'Please check your mail',
                error: 'Email is not valid'
            });
            navigate('/login');
        } else {
            setError(true);
            console.log("Email invalid");
        }
    }

    /* jshint ignore:start */
    return (
        <div className='d-flex flex-column site-auth-wrapper'>
            <HeaderAuthentication/>
            <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
                <div className="container">
                    <div className="form-wrapper">
                        <div className="form-heading text-center">
                            <h3>Let us know it’s you</h3>
                            <p>Enter your email to receive Code</p>
                        </div>
                        <form>
                            <div className="field-wrapper">
                                <div
                                    className="mb__20 position-relative">
                                    {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                    <input type="email" className="form-control" placeholder="Enter Email"
                                           onChange={(e) => {
                                               setEmail(e.target.value)
                                           }}/>
                                    {error ? <div className="validation-msg">This is not a valid email</div> : null}
                                </div>
                            </div>
                            <div className="button-wrap mb-0">
                                <button type='button' onClick={submitHandler} className='btn btn-primary'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
    /* jshint ignore:end */
}
