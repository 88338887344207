import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDashboard from '../common/HeaderDashboard'
import ResellerHeader from '../common/ResellerHeader'
import Svg from '../common/Svg'

export default function SubscriptionReports() {
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <ResellerHeader />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            <div className="report-analytics">
                                <Link to="/" className="d-flex report-analytics-wrap">
                                    <div className="title flex-grow-1">incomplete-as-at-2022-09-27- Matthew Reacher.csv</div>
                                    <div className="icon">
                                        <Svg name="download-icon" />
                                    </div>
                                </Link>
                                <Link to="/" className="d-flex report-analytics-wrap">
                                    <div className="title flex-grow-1">2022-08-subscriptions-34784206473-Matthew Reacher.csv </div>
                                    <div className="icon">
                                        <Svg name="download-icon" />
                                    </div>
                                </Link>
                                <Link to="/" className="d-flex report-analytics-wrap">
                                    <div className="title flex-grow-1">2022-08-subscriptions-34784206473-Matthew Reacher.csv </div>
                                    <div className="icon">
                                        <Svg name="download-icon" />
                                    </div>
                                </Link>
                                <Link to="/" className="d-flex report-analytics-wrap">
                                    <div className="title flex-grow-1">2022-08-subscriptions-34784206473-Matthew Reacher.csv </div>
                                    <div className="icon">
                                        <Svg name="download-icon" />
                                    </div>
                                </Link>
                                <Link to="/" className="d-flex report-analytics-wrap">
                                    <div className="title flex-grow-1">2022-08-subscriptions-34784206473-Matthew Reacher.csv </div>
                                    <div className="icon">
                                        <Svg name="download-icon" />
                                    </div>
                                </Link>
                                <Link to="/" className="d-flex report-analytics-wrap">
                                    <div className="title flex-grow-1">2022-08-subscriptions-34784206473-Matthew Reacher.csv </div>
                                    <div className="icon">
                                        <Svg name="download-icon" />
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
