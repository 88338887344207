import React from 'react'
import { Link } from 'react-router-dom'
import HeaderAuthentication from '../../common/HeaderAuthentication'

export default function ResetPassword() {
    return (
        <div className='d-flex flex-column site-auth-wrapper'>
            <HeaderAuthentication />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
                <div className="container">
                    <div className="form-wrapper">
                        <div className="form-heading text-center">
                            <h3>Reset password</h3>
                            <p>Your new password must be different from previously used passwords.</p>
                        </div>
                        <div className="field-wrapper">
                            <div className="mb__20 position-relative">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter new password"
                                />
                                <span className={"forgot-wrap "}>
                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                </span>
                            </div>

                            <div className="mb__20 position-relative">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm new password"
                                />
                                <span className={"forgot-wrap "}>
                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                </span>
                            </div>

                        </div>
                        <div className="button-wrap mb-0 pt-2">
                            <Link to="/success-message">
                                <button className='btn btn-primary'>Set new password</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
