import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUiContext } from '../../../contexts/UiContext';
import HeaderDashboard from '../../common/HeaderDashboard';
import SettingNavigation from '../../common/SettingNavigation';
import { getLocal, checkUserRoutePermission } from '../../../helper/common';

export default function BillableSubscriptions({ route }) {
    let role = getLocal("role");
    const { apiRequest } = useUiContext();
    const [data, setData] = useState([]);
    let uid = localStorage.getItem("customeruuid");
    const endpoint = (route === 'customer') ? 'customers' : (route === 'reseller') ? 'sellers' : 'distributors';
    const getData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        };

        const res = await apiRequest("get", `api/1/${endpoint}/${uid}/get_summary_subscriptions_active_for/`, "", headers, true);
        if (res) {
            setData(res.data.rows);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    // const endSubscription = async (productCode) => {
    //     const headers = {
    //         'Authorization': 'Bearer ' + localStorage.getItem("token"),
    //         'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
    //     }
    //     setLoading(true);
    //     const res = await apiRequest('get', `api/1/subscriptions?customer_uuid=${uid}&product_code=${productCode}`, "", headers)
    //     if (res) {
    //         if (res.data.results.length > 0) {
    //             res.data.results.map(async (row) => {
    //                 await apiRequest('get', `api/1/subscriptions/${row.subscription_key}/end_billable/`,"",headers);
    //                 setLoading(false);
    //             })
    //         }
    //     }
    // }
    return (
        <>
            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <SettingNavigation />


                        <div className="flex-grow-1 setting-page-wrapper">

                            <div className="container">

                                <div className='user-permissions usr-prm devices-storage-wrapper'>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        {data?.map((rows, i) => (
                                                            <tr key={i}>
                                                                {i == 0 && rows?.map((row, index) => (
                                                                    <th key={index}>{row}  </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody>
                                                        {data?.map((rows, i) => (
                                                            <tr key={i}>
                                                                {i > 0 && rows?.map((row, index) => (
                                                                    <td key={index}>{row}  </td>

                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {checkUserRoutePermission(role, `/${route}/end-subscriptions`) && <div className="button-wrap pt-3 text-center">
                                                <Link to={`/${route}/end-subscriptions`} className='btn btn-delete btn-rounded btn-300'>End subscription </Link>
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
