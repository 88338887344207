import React from 'react'
import HeaderDashboard from '../common/HeaderDashboard'
import ResellerHeader from '../common/ResellerHeader'

export default function DistributorsCustomers() {
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <ResellerHeader />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            <div className='user-permissions usr-prm devices-storage-wrapper'>
                                <div className="devices-storage-list">
                                    <div className="theme-table">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Entity Type</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">ABN</th>
                                                        <th scope="col">Email</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>test customer one</td>
                                                        <td>34 784 206 473</td>
                                                        <td>Billing test customer one  billing-test-customer-one@example.com.invalid </td>
                                                    </tr>



                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
