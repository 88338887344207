import React, { useState, useEffect } from "react";
import { formValidation } from "../../helper/common";
import HeaderDashboard from "../common/HeaderDashboard";

export default function HelpCenter() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    message: "",
  });
  useEffect(() => {
    formValidation(formData, setIsDisabled, setEmailError);
  }, [formData]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div className="d-flex flex-column site-main-wrapper content-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height content-page d-flex flex-column">
          <div className="page-title text-center">
            <div className="container">
              <h1>How can we help?</h1>
            </div>
          </div>
          <div className="flex-grow-1 content-page-wrapper">
            <div className="container">
              <div className="section-faq">
                <div className="section-title">
                  <h2>FAQs</h2>
                </div>
                <div className="faq-accordion-wrap">
                  <div className="accordion" id="accordionFAQ">
                    <div className="accordion-item">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                      >
                        How to add a new camera?
                      </button>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionFAQ"
                      >
                        <div className="accordion-body">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Mauris suspendisse et ut felis amet parturient
                          non, adipiscing. Nulla diam, nunc scelerisque ac
                          mattis ultricies malesuada sit ipsum. Fermentum
                          integer viverra euismod velit faucibus tellus ultrices
                          vulputate.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        Where I can find my subscription details ?
                      </button>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionFAQ"
                      >
                        <div className="accordion-body">
                          It is hidden by default,
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        How to group two or more cameras?
                      </button>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionFAQ"
                      >
                        <div className="accordion-body">
                          though the transition does limit overflow.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-form">
                <div className="section-title">
                  <h2>Can’t find an answer to your question? </h2>
                  <p>
                    Ask us anything, we are here to help you to deal with
                    anything.
                  </p>
                </div>
                <div className="form-wrapper theme-form-wrapper">
                  <form>
                    <div className="mb-3">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Your messge"
                        className="form-control"
                      />
                    </div>
                    <div className="button-wrap">
                      <button
                        className="btn btn-primary btn-rounded"
                        disabled={isDisabled}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
