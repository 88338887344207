
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useUiContext } from '../../../contexts/UiContext'
import { formValidation } from '../../../helper/common'
import HeaderDashboard from '../../common/HeaderDashboard'
import SettingNavigation from '../../common/SettingNavigation'
import Svg from '../../common/Svg'

export default function CreateReseller() {

    let uid = localStorage.getItem("customeruuid");
    let timezone = localStorage.getItem('tz');
    const { apiRequest } = useUiContext();
    const [disabled, setDisabled] = useState(true);
    const [distributors, setDistributors] = useState([]);
    const [emailError, setEmailError] = useState(true);
    const [summaryData, setSummaryData] = useState([]);
    const [form_choices, setForm_choices] = useState();
    const [errors, setErrors] = useState({
        abn: "",
        seller_type: "",
        contact_email: ""
    });
    const [formData, setFormData] = useState({
        name: "",
        abn: "",
        seller_type: "reseller",
        sellers : [{
            seller_uuid: '',
        }],
        contact_name: "",
        contact_email: "",
        timezone: timezone,
        resend: true,
        // distributors: "",
    })

    useEffect(() => {
        getFormChoices();
        getDistributors();
        getSummary();
    }, [])
    useEffect(() => {
        const { name, abn, seller_type, contact_name, contact_email } = formData
        formValidation({ name, abn, seller_type, contact_name, contact_email }, setDisabled, setEmailError)
    }, [formData])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const getDistributors = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/distributors/`, "", headers, true);
        if (res) {
            setDistributors(res.data.results)
        }

    }

    const getFormChoices = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api_auth/1/form_choices`, {}, headers, true, {})
        if(res.status === 200){
            setForm_choices(res.data);
        }
    }   

    const createSeller = async (e) => {
        e.preventDefault();
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('post', `api/1/distributors/${uid}/create_seller/`, formData, headers, true, { success: 'created Successfully', error: 'seller not created' })
        if (res) {
            if (res.response && res.response.data) {
                const errorsData = res.response.data.errors;
                let newErrorState = {
                    abn: "", seller_type: "", contact_email: ""
                }
                if(errorsData){
                    if (errorsData.abn) {
                        newErrorState.abn = errorsData.abn[0]
                    }
                    if (errorsData.seller_type) {
                        newErrorState.seller_type = errorsData.seller_type[0];
                    }
                    if (errorsData.contact_email) {
                        newErrorState.contact_email = errorsData.contact_email[0];
                    }
                    if (errorsData.length > 0) {
                        setFormData({
                            name: "",
                            abn: "",
                            seller_type: "reseller",
                            contact_name: "",
                            contact_email: "",
                            distributors: "",
                        })
                        getSummary();
                    }
                }
                setErrors(newErrorState)

            } else {
                setFormData({
                    name: "",
                    abn: "",
                    seller_type: "reseller",
                    contact_name: "",
                    contact_email: "",
                    distributors: "",
                })
                getSummary();
            }
        }
    }
    const getSummary = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/sellers/`, "", headers, true);
        if (res) {
            setSummaryData(res.data.results)
        }
    }
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">

                        <div className="container">
                            <div className="user-permissions">
                                <Link to="/distributor/resellers-system-integrators" className="back-btn d-inline-flex align-items-center">
                                    <Svg name="left-arrow" /> Back
                                </Link>
                                <div className="create-user">
                                    <div className="page-heading">
                                        <h3>Create new user</h3>
                                    </div>
                                    <div className="create-user-wrapper">
                                        <div className="form-wrapper theme-form-wrapper">
                                            <form>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3 position-relative">
                                                            <label>Name</label>
                                                            <input type="text" placeholder="Your Name" value={formData.name} name="name" className="form-control" onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3 position-relative">
                                                            <label>ABN</label>
                                                            <input type="text" placeholder="Abn" name="abn" className="form-control" value={formData.abn} onChange={handleChange} />
                                                            {errors.abn !== '' && <div className="validation-msg" onClick={() => setErrors({ ...errors, abn: '' })}>{errors.abn}</div>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 position-relative">
                                                    <label>Sellers</label>
                                                    <select className="form-control" name="seller_type" value={formData.seller_type} onChange={handleChange}>
                                                        {form_choices !== undefined &&  Object.entries(form_choices.seller_types).map((sellerType, i) => {
                                                           return <option key={i} defaultValue={sellerType[0] === 'reseller' ? 'reseller': ''} value={sellerType[0]}>{sellerType[1]}</option> 
                                                        })}

                                                    </select>
                                                    {errors.seller_type !== '' && <div className="validation-msg" onClick={() => setErrors({ ...errors, seller_type: '' })}>{errors.seller_type}</div>}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3 position-relative">
                                                            <label>Billing Contact Name</label>
                                                            <input type="text" placeholder="Billing Contact Name" className="form-control" name="contact_name" value={formData.contact_name} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3 position-relative">
                                                            <label>Billing Contact Email</label>
                                                            <input type="email" placeholder="Billing Contact Email" className="form-control" name="contact_email" value={formData.contact_email} onChange={handleChange} />
                                                            {errors.contact_email !== '' && <div className="validation-msg" onClick={() => setErrors({ ...errors, contact_email: '' })}>{errors.contact_email}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 position-relative">
                                                    <label>Distributors</label>
                                                    <select name="distributors" className="form-control" value={formData.distributors} multiple onChange={handleChange}>
                                                        {distributors.map((res, index) => (
                                                            <option value={res.distributor_uuid} key={index}>{res.name + "(" + res.abn + ")"}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="button-wrap text-center text-lg-start">
                                                    <button className="btn btn-primary btn-rounded" disabled={disabled} onClick={createSeller}>Create</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/*use this if required */}
                                <div className="existing-user">

                                    <div className="theme-table">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Entity Type	 </th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">ABN</th>
                                                        <th scope="col">Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {summaryData?.map((res, index) => (
                                                        <tr key={index}>
                                                            <td>{res.seller_type}	 </td>
                                                            <td>{res.name} </td>
                                                            <td>{res.abn}</td>
                                                            <td><a href={`mailto:${res.contact_email}`}>
                                                                <Svg name="mail" />{res.contact_email_addr}</a></td>
                                                        </tr>))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
