import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDashboard from '../../common/HeaderDashboard'
import ResellerHeader from '../../common/ResellerHeader'
import SettingNavigation from '../../common/SettingNavigation'

export default function UsersSummary() {
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <ResellerHeader />
                    <div className="flex-grow-1 setting-page-wrapper">

                        <div className="container">
                            <div className="user-permissions usr-prm devices-storage-wrapper">
                                <div className="devices-storage-list">
                                    <div className="user-prm-list">
                                        <div className="d-flex data-list">
                                            <div className="title flex-grow-1">User Is Admin</div>
                                            <div className="data">1</div>
                                        </div>
                                        <div className="d-flex data-list">
                                            <div className="title flex-grow-1">User Is Active</div>
                                            <div className="data">5</div>
                                        </div>
                                        <div className="d-flex data-list">
                                            <div className="title flex-grow-1">Total</div>
                                            <div className="data text-primary">6</div>
                                        </div>
                                    </div>
                                    <div className="button-wrap text-center pt-lg-4 pt-3">
                                        <Link to="/reseller/user-summary/create-user" className='btn btn-secondary btn-rounded btn-300'>Create/ View user</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
