import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DetailsSubmenu from "../../common/DetailsSubmenu";
import HeaderDashboard from "../../common/HeaderDashboard";
import RecordingDetailsSidebar from "../../common/RecordingDetailsSidebar";
import Svg from "../../common/Svg";
// import Camera1 from '../../../assets/images/camera-1.png';
import { useLocation } from "react-router-dom";
import { useUiContext } from "../../../contexts/UiContext";
// import { baseUrl } from '../../../constants/common';
import FullScreenImg from "../../../assets/images/full-screen.svg";

import imgCamera1 from "../../../assets/images/camera-1.png";
import { QUAD } from "../../../constants/common";
import FullScreenModal from "../../common/FullScreenModal";

export default function LiveView({ route }) {
  const navigate = useNavigate();
  const { device_uuid } = useParams();
  const [device, setDevice] = useState({});
  const location = useLocation();
  const { apiRequest } = useUiContext();
  // const [deviceID, setDeviceID] = useState(null);
  const [liveUrl, setLiveUrl] = useState("");
  const [isDeletetingDevice, setIsDeletetingDevice] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [streamViewDropdown, setStreamViewDropdown] = useState([]);
  const [activeStream, setActiveStream] = useState("");
  const [allowedDeviceType, setAllowedDeviceType] = useState("");
  // const [quadDisplay, setQuadDisplay] = useState([{
  //   camera_title: '',
  //   live_url: ''
  // }])
  // const [quadView, setquadView] = useState(false)

  const getLiveVideo = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
      "get",
      `api/1/devices/${device_uuid}/mjpg_img_src/`,
      {},
      headers,
      true
    ).then((res) => {
      const status = res.response.status;
      if (status === 301 || status === 302) setLiveUrl(res.response.data);
      else console.warn(res.response);
    });
  };
  useEffect(() => {
    if (device_uuid && location) {
      getLiveVideo().then((_) => {});
    }
  }, [device_uuid, location]);

  const getDevice = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
      "get",
      `api/1/devices/${device_uuid}/`,
      {},
      headers,
      true
    ).then((res) => {
      setDevice(res.data);
      setAllowedDeviceType(res.data.allowed_device_type);
      if (res.data.camera_choices) {
        let camera_choices = res.data.camera_choices;
        setStreamViewDropdown(camera_choices);
        setActiveStream(res.data.camera_default);
      }
    });
  };

  // useEffect(() => {
  //   if(allowedDeviceType === QUAD){
  //     getLiveUrlForAllowedDeviceType();
  //   }
  // }, [allowedDeviceType])

  // const getLiveUrlForAllowedDeviceType = async () => {
  //   let tempArray = [];
  //   for(let i = 0; i < streamViewDropdown.length; i++){
  //     try {
  //       const headers = {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //         "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
  //       };
  //       await apiRequest(
  //         "get",
  //         `api/1/devices/${device_uuid}/mjpg_img_src/?camera=${streamViewDropdown[i][0]}`,
  //         {},
  //         headers,
  //         true
  //       ).then((res) => {
  //         if (res.response.data !== "") {
  //           let url = res.response.data;
  //           let obj = { camera_title: streamViewDropdown[i][1], live_url: url}
  //           tempArray.push(obj);
  //         }
  //       });
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   }
  //   setQuadDisplay(tempArray)
  // }

  // function getQuadView(){
  //   setquadView(true)
  // }

  // useEffect(() => {
  //   setquadView(quadView)
  // }, [quadView])

  function requestFullscreen(id) {
    document
      .getElementById(id)
      ?.requestFullscreen()
      .then((_) => {});
  }

  function openFullscreen() {
    let image = document.getElementById("image");
    let requestFullscreen = image.requestFullscreen || image.mozRequestFullScreen || image.webkitRequestFullscreen || image.msRequestFullscreen;
    if (requestFullscreen) {
      requestFullscreen.call(image);
    } else {
      setIsModalOpen(true);
    }
    // document
    //   .getElementById("image")
    //   ?.requestFullscreen()
    //   .then((_) => {});
  }

  const deleteDevice = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    setIsDeletetingDevice(true);
    await apiRequest(
      "delete",
      `api/1/devices/${device_uuid}`,
      {},
      headers,
      true,
      { success: "Device deleted successfully", error: "", showToast: true }
    )
      .then((res) => {
        navigate("/customer/cameras");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDeletetingDevice(false);
      });
  };

  const getLiveStream = async (cameraNumber) => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
      };
      return await apiRequest(
        "get",
        `api/1/devices/${device_uuid}/mjpg_img_src/?camera=${cameraNumber}`,
        {},
        headers,
        true
      ).then((res) => {
        if (res.response.data !== "") {
          let url = res.response.data;
          if(liveUrl !== url) window.stop();
          setLiveUrl(url);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    return () => {
      window.stop();
    }
  },[])

  useEffect(() => {
    if (device_uuid) {
      getDevice(device_uuid);
    }
  }, [device_uuid]);

  const isAdmin = localStorage.getItem("isadmin") === "true";
  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        {/* listing page */}

        <div className="main-page-height devices-page d-flex">
          <RecordingDetailsSidebar route={route} />
          <div className="flex-grow-1">
            <div className="d-flex flex-column">
              <div className="camera-header d-flex align-items-center details-header">
                {device?.status === "ok" ? (
                  <div className="dropdown">
                    <button
                      className="btnn"
                      type="button"
                      id="dropdownSorting"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Svg name="camera-active" />
                    </button>
                  </div>
                ) : (
                  <div className="dropdown">
                    <button
                      className="btnn"
                      type="button"
                      id="dropdownSorting"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Svg name="camera-inactive" />
                    </button>
                  </div>
                )}
                <div className="flex-grow-1 available-heading">
                  <h2>{device?.name}</h2>
                </div>
                <div className="dropdown-wrap ms-3 me-3">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary btn-rounded"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Stream View
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {streamViewDropdown.map((choice, i) => {
                        return (
                          <li
                            key={i}
                            className={
                              activeStream === choice[0]
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            onClick={() => {
                              // setquadView(false);
                              getLiveStream(choice[0]);
                              setActiveStream(choice[0]);
                            }}
                          >
                            {choice[1]}
                          </li>
                        );
                      })}
                      {/* {allowedDeviceType === QUAD && 
                        <li 
                          className="dropdown-item"
                          style={{ color: activeStream === 'Combined View' && 'var(--primary-color)'}}
                          onClick={() => {
                            getQuadView()
                            setActiveStream('Combined View');
                          }}
                        >
                          Combined View                          
                        </li> } */}
                    </ul>
                  </div>
                </div>
                {isAdmin && (
                  <div className="button-wrap" style={{marginRight: "10px"}}>
                    <button
                      onClick={() => {
                        navigate(
                          `/${route}/cameras/details/${device.device_uuid}`
                        );
                      }}
                      className="btn btn-notif ms-1 details-btn btn-setting"
                    >
                      <Svg name="setting" />
                    </button>
                  </div>
                )}
                {isAdmin ? (
                  <div className="button-wrap">
                    <button
                      className="btn btn-delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                    >
                      <Svg name="delete-icon" />
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex-grow-1 details-view">
                <DetailsSubmenu route={route} />
                <div className="live-vew-page">
                  {device?.status === "ok" ? (
                    <div className="card-camera-body">
                      <span className="status-live">
                        {/* Use class inactive in case of camera not active*/}
                        <span className="status active"></span> Live
                      </span>
                      <span
                        id='fullScreenBtn'
                        className="full-screen-btn"
                        onClick={openFullscreen}
                      >
                        <img src={FullScreenImg} alt="Live" />
                      </span>
                      <img id="image" src={liveUrl} alt="Live" />
                    </div>
                  ) : (
                    <div className="card-camera-body no-subscriptions">
                      <div className="alert alert-primary">
                        {device?.device_description}
                      </div>
                    </div>
                  )}
                </div>
                {/* {quadView === false ? '' 
                :
                  <div className="camera-list-wrapper">
                    <span className="status-live">
                      <span className="status active"></span> Live
                    </span>
                    <div className="row justify-content-center">
                        <div className="col-xxl-8">
                        <div className="camera-row row ">
                          {quadDisplay.map((data, i) => {
                            return (<div className="col-md-6 mb-4" key={i}>
                              <div className="card-camera">
                                <div className="card-header d-flex">
                                  <div className="camera-status">
                                    <Svg name="camera-active" />
                                  </div>
                                  <div className="camera-details flex-grow-1 d-flex align-items-center">
                                    <div className="flex-grow-1 camera-name">
                                      {data.camera_title}
                                    </div>

                                    <div className="dropdown notification-dropown ">
                                      <button
                                        className="btn-notif"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <Svg name="notification" />
                                      </button>
                                      <div
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <div className="notif-header">
                                          <a href="#">Mark all as read</a>
                                        </div>
                                      </div>
                                    </div>
                                    <Link
                                      to=""
                                      className="btn-notif ms-1 details-btn"
                                    >
                                      <Svg name="setting" />
                                    </Link>
                                  </div>
                                </div>

                                <Link to="">
                                  <div className="card-camera-body">
                                    <img
                                      id={`${data.camera_title+[i]}`}
                                      src={data.live_url}
                                      alt=""
                                      className="img-fluid"
                                      onClick={() => requestFullscreen(data.camera_title+[i])}
                                    />
                                  </div>
                                </Link>
                              </div>
                            </div>)
                          })
                          }
                        </div>
                      </div>
                    </div>
                  </div>                
                }                 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-delete"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-wrap">
                <h4>Are you sure you want to delete this device? </h4>
                <div className="note">
                  <div className="d-flex heading">
                    <Svg name="delete-modal-icon" />
                    <div className="flex-grow-1 ps-3">Warning</div>
                  </div>
                  <p>All logs for this device will also be deleted.</p>
                </div>
              </div>
              <div className="button-wrap d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-delete-new"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    deleteDevice();
                  }}
                >
                  {isDeletetingDevice ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FullScreenModal
        isOpen={isModalOpen}
        style={{ width: window.innerWidth, height: window.innerHeight }}
      >
        <img
          id="fullScreenImage"
          src={liveUrl}
          alt="Live"
          style={{ width: "100%", height: "auto" }}
        />
        <button
          type="button"
          className="btn-close"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsModalOpen(false);
          }}
          aria-label="Close"
        >
          <Svg name="close-rounded" />
        </button>
      </FullScreenModal>
    </div>
  );
}
