import React, { useState, useEffect } from "react";
import Svg from "./Svg";
import { useUiContext } from "../../contexts/UiContext";

export default function GroupSidebar(props) {
  const {enable, handleAddDevice, setEnable, refreshData} = props;
  const {setCheckedDevice} = props;
  const [groupDevice, setGroupDevice] = useState({
    device: [],
    checkedList: {},
    camera: [],
  });
  const [isActive, setIsActive] = useState(true);
  const {apiRequest, setLoading} = useUiContext();

  const handleActiveFilter = () => {
    setIsActive((prev) => !prev);
  };

  const groupsList = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    setLoading(true);
    apiRequest("get", "api/1/devices/", "", headers, false).then(res => {
      let checkedList = {};
      res.data.results.map((el) => {
        checkedList[el.device_uuid] = false;
        return el;
      });
      setGroupDevice({
        ...groupDevice,
        device: res.data.results,
        checkedList: checkedList,
      });
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.error(err);
    });
  };
  useEffect(() => {
    groupsList().then(_ => {
    });
  }, [refreshData]);

  const checkedHandler = (id) => {
    let checkedList = groupDevice.checkedList;
    checkedList["" + id] = !checkedList["" + id];
    setGroupDevice({...groupDevice, checkedList: checkedList});
    let cameraIds = [];
    groupDevice.device.map(async (el) => {
      if (checkedList["" + el.device_uuid] === true) {
        cameraIds.push(el.device_uuid);
      }
    });
    setCheckedDevice(cameraIds);
  };
  const isAdmin = localStorage.getItem("isadmin") === "true";

  return (
      <div
          className={
            enable ? "sidebar-theme groups" : "sidebar-theme groups active"
          }
      >
        <div className="camera-header d-lg-flex align-items-center d-none">
          <div className="flex-grow-1 available-heading">
            <div className="d-flex justify-content-between camera-switch-wrap">
              <h3>Cameras</h3>
              <div className="check-switch d-flex">
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="radio"
                      name="activeInactive"
                      id="active"
                      defaultChecked
                  />
                  <label
                      className="form-check-label active"
                      htmlFor="active"
                      onClick={handleActiveFilter}
                  >
                    Active
                  </label>
                </div>
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="radio"
                      name="activeInactive"
                      id="inactive"
                  />
                  <label
                      className="form-check-label inactive"
                      htmlFor="inactive"
                      onClick={handleActiveFilter}
                  >
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="camera-header mobile-title-bar d-flex align-items-center justify-content-center d-lg-none">
        <span onClick={() => setEnable(true)} className="back-btn">
          <Svg name="left-arrow"/>
        </span>
          <div className="available-heading">
            <h3>Select cameras to add</h3>
          </div>
        </div>

        <div className="sidebar-wrapper">
          <div className="camera-switch-wrap d-lg-none d-flex justify-content-center">
            <div className="check-switch d-flex">
              <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="activeInactive1"
                    id="active1"
                    defaultChecked
                />
                <label
                    className="form-check-label active"
                    htmlFor="active1"
                    onClick={handleActiveFilter}
                >
                  Active
                </label>
              </div>
              <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="activeInactive1"
                    id="inactive1"
                />
                <label
                    className="form-check-label inactive"
                    htmlFor="inactive1"
                    onClick={handleActiveFilter}
                >
                  Inactive
                </label>
              </div>
            </div>
          </div>
          {isAdmin ? (
              <div className="button text-end pt-3 pb-2">
                <button
                    className="btn btn-link p-0"
                    onClick={() => handleAddDevice()}
                >
                  Add Selected
                </button>
              </div>
          ) : null}

          <div className="cameras-list active-list">
            {isActive
                ? groupDevice?.device.map(
                    (data, Index) =>
                        data.is_active === true && (
                            <div
                                className="camera-wrap d-flex align-items-center"
                                key={data.device_uuid}
                            >
                              <div className="flex-grow-1">
                                <div className="form-check">
                                  <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`device${Index}`}
                                      checked={groupDevice.checkedList["" + data.device_uuid]}
                                      onChange={() => checkedHandler(data.device_uuid)}
                                      // disabled={enable}
                                  />
                                  <label
                                      className="form-check-label d-flex align-items-center"
                                      htmlFor={`device${Index}`}
                                  >
                                    <Svg name="camera-active"/>
                                    <div className="flex-grow-1 ps-2">{data.name}</div>
                                  </label>
                                </div>
                              </div>
                            </div>
                        )
                )
                : groupDevice?.device.map(
                    (data, Index) =>
                        data.is_active === false && (
                            <div
                                className="camera-wrap d-flex align-items-center"
                                key={data.device_uuid}
                            >
                              <div className="flex-grow-1">
                                <div className="form-check">
                                  <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`device${Index}`}
                                      checked={groupDevice.checkedList["" + data.device_uuid]}
                                      onChange={() => checkedHandler(data.device_uuid)}
                                      // disabled={enable}
                                  />
                                  <label
                                      className="form-check-label d-flex align-items-center"
                                      htmlFor={`device${Index}`}
                                  >
                                    <Svg name="camera-inactive"/>
                                    <div className="flex-grow-1 ps-2">
                                      {data.device_uuid}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                        )
                )}
          </div>
        </div>
      </div>
  );
}
